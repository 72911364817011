@charset "utf-8";
/* ========== elementor ========== */
.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
    padding: 0!important;
}
.elementor-column-gap-default>.elementor-column>.elementor-element-populated {
    padding: 0!important;
}
.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1330px!important;
}
.l-main--page .elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px!important;
}
.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated>.elementor-widget-wrap {
    padding: 0!important;
}
.elementor-row {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.elementor-section.elementor-section-boxed>.elementor-container {
    padding: 0 30px;
}
.elementor-section {
    margin-bottom: 80px;
    &:last-child {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 767px) {
.elementor-section.elementor-section-boxed>.elementor-container {
    padding: 0 16px;
}
.elementor-section {
    margin-bottom: 60px;
}
}

/* ========== flexbox ========== */
.flex {
    display: -webkit-flex;
    display:    -moz-flex;
    display: -ms-flexbox;
    display:         flex;
    -ms-flex-pack: justify;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

/* ========== btn ========== */
.c-btn01 {
    display: inline-block;
    text-align: center;
    position: relative;
    line-height: 1.4;
    padding-right: 1em;
    color: $c01;
    background-image: url(../img/btn01-ico-gr.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 10px;
    span {
        display: inline-block;
        border-radius: 10px;
        border: 1px solid $c01;
        padding: .2em 1em .1em;
    }
    &.white {
        color: #fff;
        background-image: url(../img/btn01-ico-wh.svg);
        span {
            border-color: #fff;
        }
    }
    &.pink {
        color: #E89C9F;
        background-image: url(../img/btn01-ico-pk.svg);
        span {
            border-color: #E89C9F;
        }
    }
}
@media only screen and (max-width: 767px) {}

.c-btn02 {
    display: inline-block;
    min-width: 320px;
    background: #9ADDDC;
    color: #fff;
    font-family: $serif;
    position: relative;
    border-radius: 40px;
    padding: .5em 2em;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    &:before {
        content: "";
        width: 6px;
        height: 12px;
        position: absolute;
        right: 22px;
        top: 50%;
        margin-top: -6px;
        background: url(../img/arrow-r01.svg) no-repeat center;
        background-size: 100%;
    }
}
@media only screen and (max-width: 767px) {
.c-btn02 {
    font-size: 18px;
}
}

.c-btnlist {
    &.col-2 {
        gap: 0 20px;
        -webkit-justify-content: center;
                justify-content: center;
        li {
            width: 36%;
        }
    }
    &.col-3 {
        -webkit-justify-content: space-between;
                justify-content: space-between;
        li {
            width: 32%;
        }
    }
    a {
        min-width: auto;
        width: 100%;
    }
}
@media only screen and (max-width: 767px) {
.c-btnlist {
    &.col-2 {
        gap: 15px 0;
        li {
            width: 100%;
        }
    }
    &.col-3 {
        gap: 15px 0;
        li {
            width: 100%;
        }
    }
}
}

.c-navlist {
    border-top: 1px solid #f2f2f2;
    border-left: 1px solid #f2f2f2;
    li {
        width: 25%;
        border-bottom: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
    }
    a {
        display: flex;
        height: 60px;
        -webkit-align-items: center;
                align-items: center;
        width: 100%;
        position: relative;
        line-height: 1.5;
        padding: 0 1.5em;
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 7px 7px;
            border-color: transparent transparent $c-base transparent;
            position: absolute;
            bottom: 6px;
            right: 8px;
        }
    }
    span {
        display: block;
    }
}
@media only screen and (max-width: 767px) {
.c-navlist {
    li {
        width: 50%;
    }
    a {
        font-size: 14px;
    }
}
}

.c-catch {
    text-align: center;
    color: $c01;
    font-family: $serif;
    font-weight: 700;
    font-size: 34px;
    line-height: 1.6;
}
@media only screen and (max-width: 767px) {
.c-catch {
    font-size: 24px;
}
}

/* ========== h2 ========== */
.c-h2 {
    margin-bottom: 40px;
    .elementor-heading-title {
        font-family: $serif;
        font-size: 32px;
        padding: .5em 1.5em;
        position: relative;
        line-height: 1.5;
        background: linear-gradient(135deg, rgba(#51BE92,.6) 0%, rgba(#C5CF1D,.6) 100%);
        span {
            font-size: 76%;
        }
    }
}
@media only screen and (max-width: 767px) {
.c-h2 {
    margin-bottom: 30px;
    .elementor-heading-title {
        font-size: 24px;
        padding: .5em .5em;
    }
}
}

/* ========== h3 ========== */
.c-h3 {
    margin-bottom: 40px;
    .elementor-heading-title {
        font-family: $serif;
        font-size: 28px;
        position: relative;
        line-height: 1.5;
        border-bottom: 2px solid #CBCAC9;
        border-left: 9px solid #EBC386;
        padding: .3em 1em;
        &:after {
            content: "";
            width: 9px;
            height: 50%;
            background: #E89C9F;
            position: absolute;
            bottom: 0;
            left: -9px;
        }
    }
}
@media only screen and (max-width: 767px) {
.c-h3 {
    margin-bottom: 30px;
    .elementor-heading-title {
        font-size: 20px;
        border-left: 5px solid #EBC386;
        padding: .3em .5em;
        &:after {
            width: 5px;
            left: -5px;
        }
    }
}
}

/* ========== h4 ========== */
.c-h4 {
    margin-bottom: 20px;
    .elementor-heading-title {
        font-family: $serif;
        font-size: 24px;
        position: relative;
        line-height: 1.5;
        padding-left: 1.5em;
        &:before {
            content: "";
            width: 23px;
            height: 23px;
            position: absolute;
            left: 0;
            top: .3em;
            background: #BBBCDE;
            transform: rotate(45deg);
        }
    }
}
@media only screen and (max-width: 767px) {
.c-h4 {
    margin-bottom: 15px;
    .elementor-heading-title {
        font-size: 18px;
        &:before {
            width: 16px;
            height: 16px;
        }
    }
}
}

/* ========== block01 ========== */
.c-block01 {
    &__txt {
        width: 60%!important;
    }
    &__img {
        width: 36%!important;
    }
    .block__txt {
        ul {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            li {
                position: relative;
                line-height: 1.5;
                margin-bottom: 1em;
                padding-left: 24px;
                &:before {
                    content: "";
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: .3em;
                    background: #FCC235;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
.c-block01 {
    &__txt {
        width: 100%!important;
        order: 2!important;
    }
    &__img {
        width: 100%!important;
        order: 1!important;
        margin-bottom: 20px;
    }
    .block__txt {
        ul {
            margin-bottom: 20px;
        }
    }
}
}

/* ========== block02 ========== */
.c-block02 {
    &__txt {
        width: 60%!important;
    }
    &__img {
        width: 36%!important;
    }
    .block__ttl {
        margin-top: 30px;
        &:first-child {
            margin-top: 0;
        }
        .elementor-heading-title {
            font-family: $serif;
            font-size: 20px;
            position: relative;
            line-height: 1.5;
            padding: 0 0 .5em 1.2em;
            border-bottom: 1px solid #C7C7C7;
            &:before {
                content: "";
                width: 15px;
                height: 15px;
                position: absolute;
                left: 0;
                top: .4em;
                background: #333;
                transform: rotate(45deg);
            }
        }
    }
    .block__txt {
        font-size: 18px;
        color: #707070;
        padding: 0 .5em;
    }
}
@media only screen and (max-width: 767px) {
.c-block02 {
    &__txt {
        width: 100%!important;
        order: 2!important;
    }
    &__img {
        width: 100%!important;
        order: 1!important;
        margin-bottom: 20px;
    }
    .block__txt {
        font-size: 16px;
        padding: 0;
    }
}
}

/* ========== block03 ========== */
.c-block03 {
    margin-bottom: 35px;
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -18px;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: #333 transparent transparent transparent;
        border-width: 18px 18px 0px 18px;
    }
    &.last {
        margin-bottom: 80px;
        &:after {
            content: none;
        }
    }
    .elementor-row {
        background: #F3F3F3;
        padding: 40px;
    }
    &__txt {
        width: 68%!important;
    }
    &__img {
        width: 26.5%!important;
        margin-left: auto;
    }
    .block__ttl {
        .elementor-heading-title {
            font-size: 20px;
            font-family: $serif;
            position: relative;
            line-height: 1.5;
            padding-left: 2em;
            .num {
                position: absolute;
                left: 0;
                top: 0;
                text-align: center;
                width: 32px;
                line-height: 32px;
                border-radius: 50%;
                background: #333;
                color: #fff;
                font-family: $sans;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
.c-block03 {
    &.last {
        margin-bottom: 60px;
    }
    .elementor-row {
        padding: 30px 25px 25px;
    }
    &__txt {
        width: 100%!important;
        margin-bottom: 20px;
    }
    &__img {
        width: 100%!important;
    }
    .block__ttl {
        .elementor-heading-title {
            font-size: 20px;
            font-family: $serif;
            position: relative;
            line-height: 1.5;
            padding-left: 2em;
            .num {
                position: absolute;
                left: 0;
                top: 0;
                text-align: center;
                width: 32px;
                line-height: 32px;
                border-radius: 50%;
                background: #333;
                color: #fff;
                font-family: $sans;
            }
        }
    }
}
}

/* ========== block04 ========== */
.c-block04 {
    font-family: $serif;
    .elementor-row {
        border: 1px solid $c-base;
        padding: 50px;
    }
    .block__ttl {
        padding-bottom: 1em;
        margin-bottom: 1em;
        border-bottom: 1px solid $c-base;
        text-align: center;
        .elementor-heading-title {
            line-height: 1.5;
            font-size: 20px;
        }
    }
}
@media only screen and (max-width: 767px) {
.c-block04 {
    .elementor-row {
        padding: 25px;
    }
    .block__ttl {
        .elementor-heading-title {
            font-size: 18px;
        }
    }
}
}

/* ========== list01 ========== */
.c-list01 {
    gap: 0 2%;
    margin-bottom: 35px;
    &.last {
        margin-bottom: 80px;
    }
    &__item {
        width: 32%!important;
        border: 2px solid #c7c7c7;
        padding: 20px;
    }
    .item__img {
        img {
            width: 100%;
        }
    }
    .item__ttl {
        .elementor-heading-title {
            font-size: 24px;
            font-family: $serif;
            position: relative;
            line-height: 1.5;
        }
    }
}
.c-list02 {
    li {
        position: relative;
        margin-bottom: .5em;
        padding-left: 24px;
        line-height: 1.6;
        &:before {
            content: "";
            width: 16px;
            height: 16px;
            background: #5C5C5C;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: .2em;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.c-list03 {
    li {
        position: relative;
        margin-bottom: .5em;
        padding-left: 30px;
        line-height: 1.6;
        &:before {
            content: "";
            width: 23px;
            height: 21px;
            background: url(../img/ico-check.svg) no-repeat center bottom;
            background-size: 100%;
            position: absolute;
            left: 0;
            top: .1em;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.c-list04 {
    counter-reset: number 0;
    li {
        position: relative;
        margin-bottom: .5em;
        padding-left: 30px;
        line-height: 1.6;
        &:before {
            counter-increment: number 1;
            content: counter(number) " ";
            width: 25px;
            line-height: 25px;
            text-align: center;
            color: #fff;
            background: #5C5C5C;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;

        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media only screen and (max-width: 767px) {
.c-list01 {
    gap: 0;
    margin-bottom: 25px;
    &.last {
        margin-bottom: 60px;
    }
    &__item {
        width: 100%!important;
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .item__ttl {
        .elementor-heading-title {
            font-size: 20px;
        }
    }
}
}

/* ========== table01 ========== */
.c-table01 {
    width: 100%;
    table-layout: fixed;
    tr {
        &:nth-child(odd) {
            background: #f4f4f4;
        }
    }
    th {
        background: $c01;
        color: #fff;
    }
    th, td {
        text-align: center;
        border-right: 1px solid #fff;
        vertical-align: top;
        &:last-child {
            border-right: none;
        }
    }
    th {
        padding: .5em 0;
        font-weight: 700;
    }
    td {
        padding: 1em 0;
        line-height: 1.4;
        .w_time {
            font-size: 12px;
            display: block;
            color: $c01;
            margin-top: .5em;
        }
    }
}
.c-table02 {
    border-top: 1px solid #CBCAC9;
    border-left: 1px solid #CBCAC9;
    line-height: 1.5;
    tr {
        border-bottom: 1px solid #CBCAC9;
    }
    th, td {
        border-right: 1px solid #CBCAC9;
        padding: 1em;
    }
    th {
        background: #FCE6DD;
    }
}
@media only screen and (max-width: 767px) {
.c-table01 {
    th, td {
        font-size: 14px;
    }
    td {
        .w_time {
            font-size: 10px;
        }
    }
}
}

/* ========== faq ========== */
.c-faq {
    margin-bottom: 40px;
    &:last-child {
        margin-bottom: 0;
    }
    &__label {
        cursor: pointer;
        position: relative;
        text-align: center;
        background: linear-gradient(45deg, rgba(185,172,153,1) 0%, rgba(152,144,136,1) 100%);
        border-radius: 40px;
        padding: 1em 70px;
        .q-txt {
            color: #fff;
            font-family: $serif;
            font-size: 32px;
            line-height: 1.3;
            display: inline-block;
            position: relative;
            &:before {
                content: "Q.";
                font-family: $sans;
                display: inline-block;
                font-size: 34px;
            }
        }
        .q-icon {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 50%;
            margin-top: -12px;
            right: 40px;
            &:before, &:after {
                content: "";
                position: absolute;
                background: #fff;
                transition: .3s;
            }
            &:before {
                width: 3px;
                height: 100%;
                top: 0;
                left: 50%;
                margin-left: -1.5px;
            }
            &:after {
                width: 100%;
                height: 3px;
                top: 50%;
                margin-top: -1.5px;
                left: 0;
            }
        }
        &.is--open {
            .q-icon {
                &:before {
                    opacity: 0;
                }
            }
        }
    }
    &__answer {
        padding: 24px 0 5px;
    }
}
@media only screen and (max-width: 767px) {
.c-faq {
    margin-bottom: 30px;
    &__label {
        border-radius: 40px;
        padding: .6em 50px .6em 30px;
        text-align: left;
        .q-txt {
            font-size: 18px;
            &:before {
                font-size: 20px;
            }
        }
        .q-icon {
            width: 20px;
            height: 20px;
            margin-top: -10px;
            right: 20px;
        }
    }
    &__answer {
        padding: 15px 0 5px;
    }
}
}