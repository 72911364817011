@charset "utf-8";
@import "config";
/*------------------------------------------
  Base Setting
------------------------------------------*/
body {
    font-family: $sans;
    background: $bg-base;
    color: $c-base;
    letter-spacing: .08em;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
}
a {
    color: $c-base;
    img {
        transition: opacity .3s;
    }
    &:hover {
        img {
            opacity: 0.7;
        }
    }
}
.hvop {
    transition: opacity .3s;
    &:hover {
        opacity: 0.7;
    }
}
img {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
}

@media only screen and (max-width: 1024px) {}
@media only screen and (max-width: 767px) {
a {
    &:hover {
        img {
            opacity: 1;
        }
    }
}
.hvop {
    &:hover {
        opacity: 1;
    }
}
}

/*------------------------------------------
  component
------------------------------------------*/
@import "component";

/*------------------------------------------
  utility
------------------------------------------*/
@import "utility";

/*------------------------------------------
  layout
------------------------------------------*/
@import "layout";

/*------------------------------------------
  header
------------------------------------------*/
@import "header";

/*------------------------------------------
  footer
------------------------------------------*/
@import "footer";

/*------------------------------------------
  project
------------------------------------------*/
@import "project";

/*------------------------------------------
  home
------------------------------------------*/
@import "home";