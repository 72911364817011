@charset "utf-8";
/* ========== main ========== */
.l-main {
    position: relative;
    z-index: 1;
    background: #fff;
    &--page {
        padding: 40px 0 100px;
    }
}
@media only screen and (max-width: 767px) {
.l-main {
    &--page {
        padding: 30px 0 80px;
    }
}
}

/* ========== page head ========== */
.l-pagehead {
    position: relative;
    padding: 0 30px;
    background-image: url(../img/pagehead-bg.jpg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    &__inner {
        height: 295px;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        max-width: 1270px;
        margin: auto;
    }
    .pagettl {
        color: #fff;
        font-family: $serif;
        line-height: 1.3;
        &--en {
            font-weight: 700;
            font-size: 63px;
            text-transform: uppercase;
        }
        &--ja {
            font-weight: 500;
            font-size: 20px;
        }
        span {
            display: block;
        }
    }
}
@media only screen and (max-width: 1024px) {
.l-pagehead {
    .pagettl {
        &--en {
            font-size: 48px;
        }
        &--ja {
            font-size: 16px;
        }
    }
}
}
@media only screen and (max-width: 767px) {
.l-pagehead {
    padding: 0 16px;
    &__inner {
        height: 180px;
    }
    .pagettl {
        &--en {
            font-size: 32px;
        }
        &--ja {
            font-size: 14px;
        }
    }
}
}

/* ========== inner ========== */
.l-inner {
    margin: auto;
    padding: 0 30px;
    max-width: 1330px;
}
@media only screen and (max-width: 767px) {
.l-inner {
    padding: 0 16px;
}
}