@charset "utf-8";
/* ========== header ========== */
body.home {
    .l-header {
        position: fixed;
        top: 0!important;
        right: 0;
        background: linear-gradient(135deg, rgba(#51BE92,1) 0%, rgba(#C5CF1D,1) 100%);
        border-radius: 0 0 0 20px / 0 0 0 20px;
        &__inner {
            height: 64px;
            padding: 0 48px;
        }
        &__logo {
            display: none;
        }
    }
}
@media only screen and (max-width: 1024px) {
body.home {
    .l-gnav {
        display: flex;
        &__sns {
            margin: 0 0 0 1.5em;
        }
    }
}
}
@media only screen and (max-width: 767px) {
body.home {
    .l-header {
        background: none;
        &__inner {
            height: 80px;
            padding: 0 16px;
        }
    }
}
}

/* ========== main ========== */
body.home {
    .l-main {
        margin-top: 100vh;
        margin-top: 100dvh;
        background: none;
    }
}
@media only screen and (max-width: 767px) {}

/* ========== mv ========== */
.p-mv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    padding: 24px;
    &__logo {
        position: absolute;
        top: 48px;
        left: 48px;
        width: 240px;
        z-index: 1;
    }
    &__lead {
        position: absolute;
        top: 138px;
        right: 96px;
        z-index: 1;
        display: flex;
        gap: 0 32px;
        .leadtxt {
            margin: 0;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            font-family: $serif;
            letter-spacing: .24em;
            &--main {
                font-size: 30px;
                -webkit-order: 2;
                    -ms-order: 2;
                        order: 2;
            }
            &--sub {
                font-size: 24px;
                -webkit-order: 1;
                    -ms-order: 1;
                        order: 1;
            }
            span {
                padding: 1em .25em .5em;
                background: #fff;
                display: inline-block;
                border-radius: 0 0 5px 5px / 0 0 5px 5px;
                position: relative;
                &:before {
                    content: "";
                    width: 100%;
                    height: 4px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: linear-gradient(0deg, rgba(#FABDBF,1) 0%, rgba(#F0CB94,1) 100%);
                }
            }
        }
    }
    &__catch {
        position: absolute;
        bottom: 52px;
        left: 52px;
        z-index: 1;
        font-family: $serif;
        text-align: center;
        background: #fff;
        width: 275px;
        line-height: 36px;
        font-size: 20px;
        letter-spacing: .24em;
        margin: 0;
        border-radius: 10px;
        &:before, &:after {
            content: "";
            width: 24px;
            height: 24px;
            position: absolute;
            background-repeat: no-repeat;
            background-size: 100%;
        }
        &:before {
            top: -4px;
            left: -4px;
            background-image: url(../img/mv-catch-left.svg);
        }
        &:after {
            right: -4px;
            bottom: -4px;
            background-image: url(../img/mv-catch-right.svg);
        }
    }
    &__slider {
        position: relative;
        width: 100%;
        height: 100%;
        .slideitem {
            width: 100%;
            height: calc(100vh - 48px);
            position: relative;
            &:before {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(0deg, rgba(84,84,84,0.2) 0%, rgba(68,68,68,0.1) 20%, rgba(0,0,0,0.1) 70%, rgba(0,0,0,0.4) 100%);
                border-radius: 20px;
            }
            span {
                height: 100%;
                &.pc {
                    display: block;
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 20px;
            }
        }
    }
    &__scroll {
        position: absolute;
        bottom: 48px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        width: 56px;
        animation: scrollDown 2s infinite;
        margin-bottom: 0;
    }
}
@keyframes scrollDown {
0% {
    margin-bottom: 0;
}
50% {
    margin-bottom: 10px;
}
100% {
    margin-bottom: 0;
}
}
@media only screen and (max-width: 767px) {
.p-mv {
    padding: 0;
    &__logo {
        top: 16px;
        left: 16px;
        width: 180px;
    }
    &__lead {
        top: auto;
        bottom: vwsp(115);
        right: auto;
        left: 16px;
        display: block;
        .leadtxt {
            -ms-writing-mode: tb-lr;
            writing-mode: horizontal-tb;
            &--main {
                font-size: vwsp(24);
                margin-bottom: vwsp(12);
            }
            &--sub {
                font-size: vwsp(16);
                letter-spacing: .36em;
            }
            span {
                padding: .3em .5em;
                border-radius: 0 vwsp(5) vwsp(5) 0 / 0 vwsp(5) vwsp(5) 0;
                &:before {
                    width: vwsp(3);
                    height: 100%;
                }
            }
        }
    }
    &__catch {
        bottom: vwsp(60);
        left: 18px;
        width: vwsp(202);
        line-height: vwsp(30);
        font-size: vwsp(14);
        border-radius: vwsp(10);
        &:before, &:after {
            width: vwsp(16);
            height: vwsp(16);
        }
        &:before {
            top: vwsp(-2);
            left: vwsp(-2);
        }
        &:after {
            right: vwsp(-2);
            bottom: vwsp(-2);
        }
    }
    &__slider {
        .slideitem {
            height: 100vh;
            &:before {
                border-radius: 0;
            }
            img {
                border-radius: 0;
            }
        }
    }
    &__scroll {
        display: none;
    }
}
}

/* ========== home common ========== */
.p-home {
    margin-bottom: 0;
    &__ttl {
        text-align: center;
        span {
            display: block;
        }
        .ttl--img {
            img {
                height: 32px;
                width: auto;
            }
        }
        .ttl--txt {
            letter-spacing: .16em;
            font-size: 24px;
            font-family: $serif;
            margin-top: 1.5em;
            strong {
                font-size: 32px;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
.p-home {
    &__ttl {
        .ttl--img {
            img {
                height: vwsp(26);
                max-height: 26px;
            }
        }
        .ttl--txt {
            margin-top: .8em;
        }
    }
}
}

/* ========== announce ========== */
.p-announce {
    background: #F9F9F9;
    &.elementor-section.elementor-section-boxed > .elementor-container {
        max-width: 100%!important;
        padding: 0!important;
    }
    .p-home__ttl {
        .ttl--img {
            img {
                height: 22px;
            }
        }
        .ttl--txt {
            font-size: 18px;
        }
    }
    &--news {
        width: 52%!important;
        padding: 95px 0 20px 48px;
        &:before {
            content: "";
            width: 48px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(135deg, rgba(#7FE2BA,1) 0%, rgba(#DEE389,1) 100%);
        }
        .p-home__ttl {
            margin-bottom: 45px!important;
        }
    }
    &--information {
        width: 48%!important;
        padding: 95px 0 0;
        background: #fff;
        .p-home__ttl {
            margin-bottom: 0!important;
        }
    }
    .newslist {
        padding: 0 30px;
        &__list {
            max-width: 580px;
            height: 240px;
            margin: auto;
            ul {
                padding-right: 16px;
            }
            li {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
                &:nth-child(2n) {
                    .item__date {
                        border-color: #C5CF1D;
                    }
                }
            }
            a {
                display: block;
                font-size: 18px;
                line-height: 1.5;
            }
            .item__date {
                font-family: $serif;
                padding-bottom: .3em;
                margin-bottom: .5em;
                border-bottom: 1px solid $c01;
            }
            .item__ttl {
                margin-bottom: 0;
            }
        }
        &__more {
            text-align: center;
            margin-top: 48px;
        }
    }
    .p-access {
        background-image: url(../img/home-information-bg.png);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: cover;
    }
}
.jspContainer {
  overflow: hidden;
  position: relative;
}
.jspPane {
  position: absolute;
}
.jspVerticalBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 100%;
}
.jspCap {
  display: none;
}
.jspHorizontalBar .jspCap {
  float: left;
}
.jspTrack {
  background: #C5CF1D;
  position: relative;
}
.jspDrag {
  background: $c01;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
}
.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag {
  float: left;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
.p-announce {
    .newslist {
        padding: 0 20px;
    }
}
}
@media only screen and (max-width: 767px) {
.p-announce {
    .p-home__ttl {
        .ttl--img {
            img {
                height: vwsp(22);
                max-height: 22px;
            }
        }
    }
    &--news {
        width: 100%!important;
        padding: 64px 16px 48px;
        &:before {
            width: 100%;
            height: 16px;
            background: linear-gradient(135deg, rgba(#DEE389,1) 0%, rgba(#7FE2BA,1) 100%);
        }
    }
    &--information {
        width: 100%!important;
        padding: 48px 0 0;
        .p-home__ttl {
            margin-bottom: 0!important;
        }
    }
    .newslist {
        padding: 0;
        &__list {
            max-width: 100%;
            height: 228px;
            a {
                font-size: 16px;
            }
        }
        &__more {
            margin-top: 32px;
        }
    }
    .p-access {
        background-position: center;
    }
}
}

/* ========== bnr ========== */
.p-bnr {
    padding: 64px 0;
    background: linear-gradient(0deg, rgba(#21B8D7,.5) 0%, rgba(#51BE92,.5) 100%);
    backdrop-filter: blur(50px);
    text-align: center;
    .elementor-image {
        img {
            width: 634px;
        }
    }
}
@media only screen and (max-width: 767px) {
.p-bnr {
    padding: 40px 0;
}
}

/* ========== features ========== */
.p-features {
    padding: 85px 0 95px;
    background-color: #fff;
    background-image: url(../img/home-features-bg.png);
    background-repeat: no-repeat;
    background-position: center top 125px;
    background-size: 1366px;
    .p-home__ttl {
        margin-bottom: 90px!important;
    }
}
@media only screen and (max-width: 767px) {
.p-features {
    overflow: hidden;
    padding: 55px 0 64px;
    background-image: url(../img/home-features-bg-sp.png);
    background-position: center top 150px;
    background-size: 100%;
    &.elementor-section.elementor-section-boxed > .elementor-container {
        padding: 0;
    }
    .p-home__ttl {
        margin-bottom: 85px!important;
    }
}
}
.features-item {
    position: relative;
    &__wrapper {
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    &__inner {
        position: relative;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }
    &__img {
        position: relative;
        &:before, &:after {
            content: "";
            position: absolute;
            z-index: 1;
            width: 42px;
            height: 42px;
            background-repeat: no-repeat;
            background-size: 100%;
        }
        &:before {
            top: -13px;
            left: -13px;
        }
        &:after {
            bottom: -13px;
            right: -13px;
        }
    }
    &__txt {
        padding: 24px 20px 0;
        .itemtxt--ttl {
            font-family: $serif;
            font-size: 24px;
            line-height: 1.333;
            font-weight: 700;
            margin-bottom: .5em;
        }
        .itemtxt--txt {
            font-weight: 400;
            margin-bottom: 0;
            letter-spacing: 0;
        }
        .itemtxt--more {
            margin-top: 1em;
        }
    }
    &__more {
        position: absolute;
        right: 13px;
        bottom: 0;
    }
    .itemtxt--more {
        a {
            display: inline-block;
            font-weight: 700;
            font-size: 18px;
        }
    }
    .label {
        position: absolute;
        top: -55px;
        right: 30px;
        z-index: 1;
        text-align: center;
        font-weight: 700;
        line-height: 1;
        font-size: 16px;
        letter-spacing: 0;
        .num {
            display: block;
            font-family: $serif;
            font-size: 79px;
            letter-spacing: -.05em;
        }
    }
    &--first {
        margin-bottom: 120px;
        .features-item__img {
            width: 62%;
            padding-left: 10px;
        }
        .features-item__txt {
            width: 36%;
            margin-left: auto;
            .itemtxt--ttl {
                font-size: 30px;
            }
            .itemtxt--more {
                a {
                    font-size: 14px;
                }
            }
        }
        .label {
            position: static;
            font-size: 13px;
            display: inline-block;
            margin-bottom: 40px;
            .num {
                font-size: 61px;
            }
            &.sp {
                display: none;
            }
        }
    }
    &--col {
        width: 33%;
        max-width: 390px;
        padding: 0 13px 45px;
        &:before {
            content: "";
            width: 100%;
            height: 438px;
            position: absolute;
            left: 0;
            top: 120px;
        }
    }
    &01 {
        .features-item__img {
            &:before, &:after {
                width: 32px;
                height: 32px;
            }
            &:before {
                top: -10px;
                left: 0;
                background-image: url(../img/features-img01-arrow01.png);
            }
            &:after {
                bottom: -10px;
                right: -10px;
                background-image: url(../img/features-img01-arrow02.png);
            }
        }
        .itemtxt--ttl {
            color: #4BAC85;
        }
        .itemtxt--more {
            a {
                color: $c01;
            }
        }
        .label {
            color: #51BE92;
        }
    }
    &02 {
        &:before {
            background: linear-gradient(0deg, rgba(#F7F3ED,0) 0%, rgba(#F0CB94,0.5) 50%, rgba(#FABDBF,1) 100%);
        }
        .features-item__img {
            &:before {
                background-image: url(../img/features-img02-arrow.png);
            }
        }
        .itemtxt--ttl {
            color: #E89C9F;
        }
        .itemtxt--more {
            a {
                color: #E89C9F;
            }
        }
        .label {
            color: #FABDBF;
        }
    }
    &03 {
        &:before {
            background: linear-gradient(0deg, rgba(#F9F9F9,0) 0%, rgba(#C5CF1D,.25) 50%, rgba(#51BE92,.5) 100%);
        }
        .features-item__img {
            &:before {
                background-image: url(../img/features-img03-arrow.png);
            }
        }
        .itemtxt--ttl {
            color: #4BAC85;
        }
        .itemtxt--more {
            a {
                color: $c01;
            }
        }
        .label {
            color: #51BE92;
        }
    }
    &04 {
        &:before {
            background: linear-gradient(0deg, rgba(#F7F3ED,0) 0%, rgba(#F0CB94,.5) 50%, rgba(#FABDBF,1) 100%);
        }
        .features-item__img {
            &:before {
                background-image: url(../img/features-img04-arrow.png);
            }
        }
        .itemtxt--ttl {
            color: #E89C9F;
        }
        .itemtxt--more {
            a {
                color: #E89C9F;
            }
        }
        .label {
            color: #FABDBF;
        }
    }
}
@media only screen and (max-width: 1024px) {
.features-item {
    &__txt {
        padding: 24px 0 0;
    }
    &--first {
        .features-item__img {
            width: 100%;
        }
        .features-item__txt {
            width: 100%;
        }
    }
}
}
@media only screen and (max-width: 767px) {
.features-item {
    &__wrapper {
        gap: 90px 0;
    }
    &__img {
        &:before, &:after {
            width: 16px;
            height: 16px;
        }
        &:before {
            top: -4px;
            left: -4px;
        }
        &:after {
            bottom: -4px;
            right: -4px;
        }
    }
    &__txt {
        padding: 32px 0 0;
        .itemtxt--ttl {
            font-family: $serif;
            font-size: 24px;
            line-height: 1.333;
            font-weight: 700;
            margin-bottom: .5em;
        }
        .itemtxt--txt {
            font-weight: 400;
            margin-bottom: 0;
            letter-spacing: 0;
        }
        .itemtxt--more {
            margin-top: 1.5em;
        }
    }
    &__more {
        position: static;
    }
    .itemtxt--more {
        a {
            font-size: 14px;
        }
    }
    .label {
        top: -50px;
        right: 30px;
        .num {
            font-size: 72px;
        }
    }
    &--first {
        margin-bottom: 80px;
        .features-item__img {
            width: 100%;
            padding-left: 0;
        }
        .features-item__txt {
            width: 100%;
            padding: 24px 16px 0;
            .itemtxt--ttl {
                font-size: 30px;
            }
        }
        .label {
            position: absolute;
            margin-bottom: 0;
            top: -37px;
            right: auto;
            left: 23px;
            display: inline-block!important;
        }
    }
    &--col {
        width: 100%;
        max-width: 100%;
        padding: 0 16px;
        &:before {
            height: 65%;
            top: vwsp(120);
        }
    }
    &01 {
        .features-item__img {
            &:before, &:after {
                width: 16px;
                height: 16px;
            }
            &:before {
                top: 0;
            }
            &:after {
                bottom: 0;
                right: 0;
            }
        }
    }
}
}

/* ========== medical ========== */
.p-medical {
    padding: 95px 0;
    background: #F9F9F9;
    &.elementor-section.elementor-section-boxed > .elementor-container {
        max-width: 100%!important;
        padding: 0!important;
    }
    .p-home__ttl {
        margin-bottom: 60px!important;
    }
    .medicallist {
        padding: 0 30px;
        position: relative;
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(#EEF2AF,1) 0%, rgba(#A0F0D0,1) 100%);
        }
        ul {
            max-width: 1270px;
            margin: auto;
            position: relative;
        }
        li {
            width: 25%;
        }
    }
}
@media only screen and (max-width: 767px) {
.p-medical {
    padding: 64px 0;
    .p-home__ttl {
        margin-bottom: 50px!important;
    }
    .medicallist {
        padding: 0 16px;
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(#EEF2AF,1) 0%, rgba(#A0F0D0,1) 100%);
        }
        li {
            width: 50%;
        }
    }
}
}

/* ========== greeting ========== */
.p-greeting {
    padding: 64px 0 96px;
    background: linear-gradient(45deg, rgba(#fff,1) 0%, rgba(#FEFFF2,1) 50%, rgba(#F2FFFA,1) 100%);
    >.elementor-container {
        background: url(../img/home-greeting-ttl.svg) no-repeat left top;
        background-size: 42px;
    }
}
@media only screen and (max-width: 767px) {
.p-greeting {
    padding: 48px 0;
    >.elementor-container {
        background: none;
    }
    .greeting-ttl {
        margin-bottom: 48px;
        img {
            height: vwsp(32);
            max-height: 32px;
        }
    }
}
}
.greeting-item {
    margin-bottom: 96px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    &:last-child {
        margin-bottom: 0;
    }
    &__txt {
        width: 48%;
        padding-left: 106px;
    }
    &__img {
        width: 48%;
        padding: 0 16px;
        position: relative;
    }
    .item__head {
        margin-bottom: 48px;
        position: relative;
        overflow: hidden;
        font-size: 24px;
        letter-spacing: .16em;
        font-family: $serif;
        line-height: 1;
        span {
            display: inline-block;
            position: relative;
            padding-right: 16px;
            border-right: 1px solid #333;
            &:after {
                content: "";
                width: 200%;
                height: 1px;
                background: #333;
                position: absolute;
                top: 50%;
                left: 100%;
            }
        }
    }
    .item__txt {
        p {
            margin-bottom: 0;
            line-height: 2.13;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0;
        }
    }
    .item__name {
        position: absolute;
        right: 0;
        top: 42px;
        z-index: 1;
        line-height: 1;
        font-size: 18px;
        font-family: $serif;
        letter-spacing: 0.16em;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        p {
            margin-bottom: 0;
            margin-left: 8px;
            &:last-child {
                margin-top: 16px;
                margin-left: 0;
            }
        }
        span {
            display: inline-block;
            background: #fff;
            padding: 1em .5em;
        }
    }
    .item__img {
        position: relative;
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: -16px;
            bottom: -16px;
            background: #000;
        }
        img {
            position: relative;
        }
        &.green {
            &:before {
                background: linear-gradient(315deg, rgba(#C5CF1D,.4) 0%, rgba(#51BE92,.4) 100%);
            }
        }
        &.pink {
            &:before {
                background: linear-gradient(315deg, rgba(#FABDBF,.4) 0%, rgba(#F0CB94,.4) 100%);
            }
        }
    }
    .item__more {
        margin-top: 64px;
        text-align: center;
    }
}
@media only screen and (max-width: 1024px) {
.greeting-item {
    &__txt {
        padding-left: 50px;
    }
}
}
@media only screen and (max-width: 767px) {
.greeting-item {
    margin-bottom: 48px;
    &__txt {
        width: 100%;
        padding-left: 0;
        -webkit-order: 2;
            -ms-order: 2;
                order: 2;
    }
    &__img {
        width: 100%;
        padding: 0;
        margin-bottom: 48px;
        -webkit-order: 1;
            -ms-order: 1;
                order: 1;
    }
    .item__head {
        margin-bottom: 32px;
    }
    .item__name {
        top: 24px;
        font-size: 16px;
        p {
            &:last-child {
                margin-top: 8px;
            }
        }
    }
    .item__more {
        margin-top: 32px;
    }
}
}

/* ========== btn ========== */
.p-btn {
    padding: 64px 0;
    background: linear-gradient(315deg, rgba(#21B8D7,.5) 0%, rgba(#51BE92,.5) 100%);
    backdrop-filter: blur(50px);
    .btnlist {
        max-width: 1010px;
        gap: 20px 0;
        margin: auto;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        a {
            display: block;
            width: 49%;
            max-width: 472px;
            text-align: center;
            position: relative;
            padding: 4px;
            color: #21B8D7;
            font-size: 32px;
            line-height: 1.5;
            background: linear-gradient(315deg, rgba(#21B8D7,1) 0%, rgba(#51BE92,1) 100%);
            &:nth-child(2n) {
                color: $c01;
                background: linear-gradient(315deg, rgba(#51BE92,1) 0%, rgba(#21B8D7,1) 100%);
            }
            &:after {
                content: "";
                width: 12px;
                height: 12px;
                position: absolute;
                bottom: 12px;
                right: 12px;
                background: url(../img/home-btn-arrow.svg) no-repeat;
                background-size: 100%;
            }
        }
        .inner {
            background: #fff;
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            height: 92px;
            border-radius: 10px;
            background-position: left 20px center;
            background-repeat: no-repeat;
        }
        .btn--faq {
            .inner {
                background-image: url(../img/home-btn-faq.svg);
                background-size: 66px;
            }
        }
        .btn--price {
            .inner {
                background-image: url(../img/home-btn-price.svg);
                background-size: 52px;
            }
        }
    }
}
@media only screen and (max-width: 1024px) {
.p-btn {
    .btnlist {
        a {
            font-size: 22px;
        }
        .inner {
            span {
                padding-left: 40px;
            }
        }
    }
}
}
@media only screen and (max-width: 767px) {
.p-btn {
    padding: 32px 0;
    .btnlist {
        gap: 32px 0;
        a {
            width: 100%;
            max-width: 100%;
            padding: 4px 8px;
            font-size: 24px;
            &:after {
                bottom: 8px;
            }
        }
        .inner {
            background-position: left 14px center;
        }
        .btn--faq {
            .inner {
                background-size: 57px;
            }
        }
        .btn--price {
            .inner {
                background-size: 52px;
            }
        }
    }
}
}

/* ========== loop ========== */
.p-loop {
    background-color: #F9F9F9;
    background-image: url(../img/home-loop-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1366px;
    padding: 32px 0;
    &.elementor-section.elementor-section-boxed > .elementor-container {
        max-width: 100%!important;
        padding: 0!important;
    }
    .slideitem {
        padding: 0 15px;
        &:nth-child(2n) {
            padding-top: 32px;
        }
    }
}
@media only screen and (max-width: 767px) {
.p-loop {
    background-image: url(../img/home-loop-bg-sp.png);
    background-size: 100%;
    padding: 32px 0;
    .slideitem {
        padding: 0 8px;
        &:nth-child(2n) {
            padding-top: 16px;
        }
    }
}
}

/* ========== contact ========== */
.p-contact {
    background: #fff;
    &:before, &:after {
        content: "";
        position: absolute;
        top: 0;
        width: 48px;
        height: 100%;
    }
    &:before {
        left: 0;
        background: linear-gradient(135deg, rgba(#F0CB94,1) 0%, rgba(#FABDBF,1) 100%);
    }
    &:after {
        right: 0;
        background: linear-gradient(135deg, rgba(#FABDBF,1) 0%, rgba(#F0CB94,1) 100%);
    }
    &.elementor-section.elementor-section-boxed > .elementor-container {
        max-width: 100%!important;
        padding: 0 48px!important;
    }
    .elementor-row {
        -webkit-align-items: center;
                align-items: center;
    }
    &__head {
        padding: 30px 58px;
    }
    &__btn {}
    .contact__ttl {
        border-bottom: 1px solid #FABDBF;
        padding-bottom: 16px;
        margin-bottom: 35px!important;
    }
    .contact__txt {
        p {
            margin-bottom: 0;
            font-weight: 400;
        }
    }
    .contact__btnlist {
        a {
            display: block;
            width: 50%;
            text-align: center;
            position: relative;
            padding: 56px 16px 0;
            min-height: 324px;
            color: #fff;
            font-family: $serif;
            line-height: 1.5;
            &:after {
                content: "";
                position: absolute;
                bottom: 8px;
                right: 8px;
                width: 0;
                height: 0;
                border-style: solid;
                border-color: transparent transparent #fff transparent;
                border-width: 0px 0px 16px 16px;
            }
        }
        .btnlist__btn {
            &--tel {
                background: #E89C9F;
            }
            &--web {
                background: #EBC386;
            }
        }
        .btnlist__icon {
            img {
                width: 120px;
            }
        }
        .btnlist__ttl {
            margin-top: 1em;
            font-size: 28px;
            margin-bottom: 0;
        }
        .btnlist__txt {
            margin-top: 1em;
            margin-bottom: 0;
        }
    }
}
@media only screen and (max-width: 1024px) {
.p-contact {
    background: #fff;
    &:before, &:after {
        width: 30px;
    }
    &.elementor-section.elementor-section-boxed > .elementor-container {
        padding: 0 30px!important;
    }
    &__head {
        padding: 30px 20px;
    }
    .contact__btnlist {
        .btnlist__ttl {
            font-size: 21px;
        }
    }
}
}
@media only screen and (max-width: 767px) {
.p-contact {
    background: #fff;
    &:before, &:after {
        width: 100%;
        height: 16px;
    }
    &:after {
        top: auto;
        bottom: 0;
    }
    &.elementor-section.elementor-section-boxed > .elementor-container {
        padding: 16px 0!important;
    }
    &__head {
        padding: 32px 16px;
    }
    &__btn {}
    .contact__ttl {
        margin-bottom: 30px!important;
        img {
            width: 335px;
        }
    }
    .contact__btnlist {
        a {
            width: 100%;
            height: auto;
            padding: 56px 16px;
        }
        .btnlist__ttl {
            font-size: 28px;
        }
    }
}
}