@charset "UTF-8";
/*------------------------------------------
  Base Setting
------------------------------------------*/
body {
  font-family: "dnp-shuei-gothic-kin-std", sans-serif;
  background: #fff;
  color: #333;
  letter-spacing: .08em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased; }

a {
  color: #333; }
  a img {
    transition: opacity .3s; }
  a:hover img {
    opacity: 0.7; }

.hvop {
  transition: opacity .3s; }
  .hvop:hover {
    opacity: 0.7; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom; }

@media only screen and (max-width: 767px) {
  a:hover img {
    opacity: 1; }
  .hvop:hover {
    opacity: 1; } }

/*------------------------------------------
  component
------------------------------------------*/
/* ========== elementor ========== */
.elementor-column-gap-default > .elementor-row > .elementor-column > .elementor-element-populated {
  padding: 0 !important; }

.elementor-column-gap-default > .elementor-column > .elementor-element-populated {
  padding: 0 !important; }

.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1330px !important; }

.l-main--page .elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1140px !important; }

.elementor-column-gap-default > .elementor-row > .elementor-column > .elementor-element-populated > .elementor-widget-wrap {
  padding: 0 !important; }

.elementor-row {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.elementor-section.elementor-section-boxed > .elementor-container {
  padding: 0 30px; }

.elementor-section {
  margin-bottom: 80px; }
  .elementor-section:last-child {
    margin-bottom: 0; }

@media only screen and (max-width: 767px) {
  .elementor-section.elementor-section-boxed > .elementor-container {
    padding: 0 16px; }
  .elementor-section {
    margin-bottom: 60px; } }

/* ========== flexbox ========== */
.flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

/* ========== btn ========== */
.c-btn01 {
  display: inline-block;
  text-align: center;
  position: relative;
  line-height: 1.4;
  padding-right: 1em;
  color: #51BE92;
  background-image: url(../img/btn01-ico-gr.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 10px; }
  .c-btn01 span {
    display: inline-block;
    border-radius: 10px;
    border: 1px solid #51BE92;
    padding: .2em 1em .1em; }
  .c-btn01.white {
    color: #fff;
    background-image: url(../img/btn01-ico-wh.svg); }
    .c-btn01.white span {
      border-color: #fff; }
  .c-btn01.pink {
    color: #E89C9F;
    background-image: url(../img/btn01-ico-pk.svg); }
    .c-btn01.pink span {
      border-color: #E89C9F; }

.c-btn02 {
  display: inline-block;
  min-width: 320px;
  background: #9ADDDC;
  color: #fff;
  font-family: "dnp-shuei-mincho-pr6n", serif;
  position: relative;
  border-radius: 40px;
  padding: .5em 2em;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center; }
  .c-btn02:before {
    content: "";
    width: 6px;
    height: 12px;
    position: absolute;
    right: 22px;
    top: 50%;
    margin-top: -6px;
    background: url(../img/arrow-r01.svg) no-repeat center;
    background-size: 100%; }

@media only screen and (max-width: 767px) {
  .c-btn02 {
    font-size: 18px; } }

.c-btnlist.col-2 {
  gap: 0 20px;
  -webkit-justify-content: center;
  justify-content: center; }
  .c-btnlist.col-2 li {
    width: 36%; }

.c-btnlist.col-3 {
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .c-btnlist.col-3 li {
    width: 32%; }

.c-btnlist a {
  min-width: auto;
  width: 100%; }

@media only screen and (max-width: 767px) {
  .c-btnlist.col-2 {
    gap: 15px 0; }
    .c-btnlist.col-2 li {
      width: 100%; }
  .c-btnlist.col-3 {
    gap: 15px 0; }
    .c-btnlist.col-3 li {
      width: 100%; } }

.c-navlist {
  border-top: 1px solid #f2f2f2;
  border-left: 1px solid #f2f2f2; }
  .c-navlist li {
    width: 25%;
    border-bottom: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2; }
  .c-navlist a {
    display: flex;
    height: 60px;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    position: relative;
    line-height: 1.5;
    padding: 0 1.5em; }
    .c-navlist a:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 7px 7px;
      border-color: transparent transparent #333 transparent;
      position: absolute;
      bottom: 6px;
      right: 8px; }
  .c-navlist span {
    display: block; }

@media only screen and (max-width: 767px) {
  .c-navlist li {
    width: 50%; }
  .c-navlist a {
    font-size: 14px; } }

.c-catch {
  text-align: center;
  color: #51BE92;
  font-family: "dnp-shuei-mincho-pr6n", serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 1.6; }

@media only screen and (max-width: 767px) {
  .c-catch {
    font-size: 24px; } }

/* ========== h2 ========== */
.c-h2 {
  margin-bottom: 40px; }
  .c-h2 .elementor-heading-title {
    font-family: "dnp-shuei-mincho-pr6n", serif;
    font-size: 32px;
    padding: .5em 1.5em;
    position: relative;
    line-height: 1.5;
    background: linear-gradient(135deg, rgba(81, 190, 146, 0.6) 0%, rgba(197, 207, 29, 0.6) 100%); }
    .c-h2 .elementor-heading-title span {
      font-size: 76%; }

@media only screen and (max-width: 767px) {
  .c-h2 {
    margin-bottom: 30px; }
    .c-h2 .elementor-heading-title {
      font-size: 24px;
      padding: .5em .5em; } }

/* ========== h3 ========== */
.c-h3 {
  margin-bottom: 40px; }
  .c-h3 .elementor-heading-title {
    font-family: "dnp-shuei-mincho-pr6n", serif;
    font-size: 28px;
    position: relative;
    line-height: 1.5;
    border-bottom: 2px solid #CBCAC9;
    border-left: 9px solid #EBC386;
    padding: .3em 1em; }
    .c-h3 .elementor-heading-title:after {
      content: "";
      width: 9px;
      height: 50%;
      background: #E89C9F;
      position: absolute;
      bottom: 0;
      left: -9px; }

@media only screen and (max-width: 767px) {
  .c-h3 {
    margin-bottom: 30px; }
    .c-h3 .elementor-heading-title {
      font-size: 20px;
      border-left: 5px solid #EBC386;
      padding: .3em .5em; }
      .c-h3 .elementor-heading-title:after {
        width: 5px;
        left: -5px; } }

/* ========== h4 ========== */
.c-h4 {
  margin-bottom: 20px; }
  .c-h4 .elementor-heading-title {
    font-family: "dnp-shuei-mincho-pr6n", serif;
    font-size: 24px;
    position: relative;
    line-height: 1.5;
    padding-left: 1.5em; }
    .c-h4 .elementor-heading-title:before {
      content: "";
      width: 23px;
      height: 23px;
      position: absolute;
      left: 0;
      top: .3em;
      background: #BBBCDE;
      transform: rotate(45deg); }

@media only screen and (max-width: 767px) {
  .c-h4 {
    margin-bottom: 15px; }
    .c-h4 .elementor-heading-title {
      font-size: 18px; }
      .c-h4 .elementor-heading-title:before {
        width: 16px;
        height: 16px; } }

/* ========== block01 ========== */
.c-block01__txt {
  width: 60% !important; }

.c-block01__img {
  width: 36% !important; }

.c-block01 .block__txt ul {
  margin-bottom: 30px; }
  .c-block01 .block__txt ul:last-child {
    margin-bottom: 0; }
  .c-block01 .block__txt ul li {
    position: relative;
    line-height: 1.5;
    margin-bottom: 1em;
    padding-left: 24px; }
    .c-block01 .block__txt ul li:before {
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: .3em;
      background: #FCC235; }
    .c-block01 .block__txt ul li:last-child {
      margin-bottom: 0; }

@media only screen and (max-width: 767px) {
  .c-block01__txt {
    width: 100% !important;
    order: 2 !important; }
  .c-block01__img {
    width: 100% !important;
    order: 1 !important;
    margin-bottom: 20px; }
  .c-block01 .block__txt ul {
    margin-bottom: 20px; } }

/* ========== block02 ========== */
.c-block02__txt {
  width: 60% !important; }

.c-block02__img {
  width: 36% !important; }

.c-block02 .block__ttl {
  margin-top: 30px; }
  .c-block02 .block__ttl:first-child {
    margin-top: 0; }
  .c-block02 .block__ttl .elementor-heading-title {
    font-family: "dnp-shuei-mincho-pr6n", serif;
    font-size: 20px;
    position: relative;
    line-height: 1.5;
    padding: 0 0 .5em 1.2em;
    border-bottom: 1px solid #C7C7C7; }
    .c-block02 .block__ttl .elementor-heading-title:before {
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      left: 0;
      top: .4em;
      background: #333;
      transform: rotate(45deg); }

.c-block02 .block__txt {
  font-size: 18px;
  color: #707070;
  padding: 0 .5em; }

@media only screen and (max-width: 767px) {
  .c-block02__txt {
    width: 100% !important;
    order: 2 !important; }
  .c-block02__img {
    width: 100% !important;
    order: 1 !important;
    margin-bottom: 20px; }
  .c-block02 .block__txt {
    font-size: 16px;
    padding: 0; } }

/* ========== block03 ========== */
.c-block03 {
  margin-bottom: 35px; }
  .c-block03:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -18px;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
    border-width: 18px 18px 0px 18px; }
  .c-block03.last {
    margin-bottom: 80px; }
    .c-block03.last:after {
      content: none; }
  .c-block03 .elementor-row {
    background: #F3F3F3;
    padding: 40px; }
  .c-block03__txt {
    width: 68% !important; }
  .c-block03__img {
    width: 26.5% !important;
    margin-left: auto; }
  .c-block03 .block__ttl .elementor-heading-title {
    font-size: 20px;
    font-family: "dnp-shuei-mincho-pr6n", serif;
    position: relative;
    line-height: 1.5;
    padding-left: 2em; }
    .c-block03 .block__ttl .elementor-heading-title .num {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      width: 32px;
      line-height: 32px;
      border-radius: 50%;
      background: #333;
      color: #fff;
      font-family: "dnp-shuei-gothic-kin-std", sans-serif; }

@media only screen and (max-width: 767px) {
  .c-block03.last {
    margin-bottom: 60px; }
  .c-block03 .elementor-row {
    padding: 30px 25px 25px; }
  .c-block03__txt {
    width: 100% !important;
    margin-bottom: 20px; }
  .c-block03__img {
    width: 100% !important; }
  .c-block03 .block__ttl .elementor-heading-title {
    font-size: 20px;
    font-family: "dnp-shuei-mincho-pr6n", serif;
    position: relative;
    line-height: 1.5;
    padding-left: 2em; }
    .c-block03 .block__ttl .elementor-heading-title .num {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      width: 32px;
      line-height: 32px;
      border-radius: 50%;
      background: #333;
      color: #fff;
      font-family: "dnp-shuei-gothic-kin-std", sans-serif; } }

/* ========== block04 ========== */
.c-block04 {
  font-family: "dnp-shuei-mincho-pr6n", serif; }
  .c-block04 .elementor-row {
    border: 1px solid #333;
    padding: 50px; }
  .c-block04 .block__ttl {
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid #333;
    text-align: center; }
    .c-block04 .block__ttl .elementor-heading-title {
      line-height: 1.5;
      font-size: 20px; }

@media only screen and (max-width: 767px) {
  .c-block04 .elementor-row {
    padding: 25px; }
  .c-block04 .block__ttl .elementor-heading-title {
    font-size: 18px; } }

/* ========== list01 ========== */
.c-list01 {
  gap: 0 2%;
  margin-bottom: 35px; }
  .c-list01.last {
    margin-bottom: 80px; }
  .c-list01__item {
    width: 32% !important;
    border: 2px solid #c7c7c7;
    padding: 20px; }
  .c-list01 .item__img img {
    width: 100%; }
  .c-list01 .item__ttl .elementor-heading-title {
    font-size: 24px;
    font-family: "dnp-shuei-mincho-pr6n", serif;
    position: relative;
    line-height: 1.5; }

.c-list02 li {
  position: relative;
  margin-bottom: .5em;
  padding-left: 24px;
  line-height: 1.6; }
  .c-list02 li:before {
    content: "";
    width: 16px;
    height: 16px;
    background: #5C5C5C;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: .2em; }
  .c-list02 li:last-child {
    margin-bottom: 0; }

.c-list03 li {
  position: relative;
  margin-bottom: .5em;
  padding-left: 30px;
  line-height: 1.6; }
  .c-list03 li:before {
    content: "";
    width: 23px;
    height: 21px;
    background: url(../img/ico-check.svg) no-repeat center bottom;
    background-size: 100%;
    position: absolute;
    left: 0;
    top: .1em; }
  .c-list03 li:last-child {
    margin-bottom: 0; }

.c-list04 {
  counter-reset: number 0; }
  .c-list04 li {
    position: relative;
    margin-bottom: .5em;
    padding-left: 30px;
    line-height: 1.6; }
    .c-list04 li:before {
      counter-increment: number 1;
      content: counter(number) " ";
      width: 25px;
      line-height: 25px;
      text-align: center;
      color: #fff;
      background: #5C5C5C;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0; }
    .c-list04 li:last-child {
      margin-bottom: 0; }

@media only screen and (max-width: 767px) {
  .c-list01 {
    gap: 0;
    margin-bottom: 25px; }
    .c-list01.last {
      margin-bottom: 60px; }
    .c-list01__item {
      width: 100% !important;
      margin-bottom: 25px; }
      .c-list01__item:last-child {
        margin-bottom: 0; }
    .c-list01 .item__ttl .elementor-heading-title {
      font-size: 20px; } }

/* ========== table01 ========== */
.c-table01 {
  width: 100%;
  table-layout: fixed; }
  .c-table01 tr:nth-child(odd) {
    background: #f4f4f4; }
  .c-table01 th {
    background: #51BE92;
    color: #fff; }
  .c-table01 th, .c-table01 td {
    text-align: center;
    border-right: 1px solid #fff;
    vertical-align: top; }
    .c-table01 th:last-child, .c-table01 td:last-child {
      border-right: none; }
  .c-table01 th {
    padding: .5em 0;
    font-weight: 700; }
  .c-table01 td {
    padding: 1em 0;
    line-height: 1.4; }
    .c-table01 td .w_time {
      font-size: 12px;
      display: block;
      color: #51BE92;
      margin-top: .5em; }

.c-table02 {
  border-top: 1px solid #CBCAC9;
  border-left: 1px solid #CBCAC9;
  line-height: 1.5; }
  .c-table02 tr {
    border-bottom: 1px solid #CBCAC9; }
  .c-table02 th, .c-table02 td {
    border-right: 1px solid #CBCAC9;
    padding: 1em; }
  .c-table02 th {
    background: #FCE6DD; }

@media only screen and (max-width: 767px) {
  .c-table01 th, .c-table01 td {
    font-size: 14px; }
  .c-table01 td .w_time {
    font-size: 10px; } }

/* ========== faq ========== */
.c-faq {
  margin-bottom: 40px; }
  .c-faq:last-child {
    margin-bottom: 0; }
  .c-faq__label {
    cursor: pointer;
    position: relative;
    text-align: center;
    background: linear-gradient(45deg, #b9ac99 0%, #989088 100%);
    border-radius: 40px;
    padding: 1em 70px; }
    .c-faq__label .q-txt {
      color: #fff;
      font-family: "dnp-shuei-mincho-pr6n", serif;
      font-size: 32px;
      line-height: 1.3;
      display: inline-block;
      position: relative; }
      .c-faq__label .q-txt:before {
        content: "Q.";
        font-family: "dnp-shuei-gothic-kin-std", sans-serif;
        display: inline-block;
        font-size: 34px; }
    .c-faq__label .q-icon {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      margin-top: -12px;
      right: 40px; }
      .c-faq__label .q-icon:before, .c-faq__label .q-icon:after {
        content: "";
        position: absolute;
        background: #fff;
        transition: .3s; }
      .c-faq__label .q-icon:before {
        width: 3px;
        height: 100%;
        top: 0;
        left: 50%;
        margin-left: -1.5px; }
      .c-faq__label .q-icon:after {
        width: 100%;
        height: 3px;
        top: 50%;
        margin-top: -1.5px;
        left: 0; }
    .c-faq__label.is--open .q-icon:before {
      opacity: 0; }
  .c-faq__answer {
    padding: 24px 0 5px; }

@media only screen and (max-width: 767px) {
  .c-faq {
    margin-bottom: 30px; }
    .c-faq__label {
      border-radius: 40px;
      padding: .6em 50px .6em 30px;
      text-align: left; }
      .c-faq__label .q-txt {
        font-size: 18px; }
        .c-faq__label .q-txt:before {
          font-size: 20px; }
      .c-faq__label .q-icon {
        width: 20px;
        height: 20px;
        margin-top: -10px;
        right: 20px; }
    .c-faq__answer {
      padding: 15px 0 5px; } }

/*------------------------------------------
  utility
------------------------------------------*/
.u-w100 {
  width: 100% !important; }

/*------------------------------------------
  layout
------------------------------------------*/
/* ========== main ========== */
.l-main {
  position: relative;
  z-index: 1;
  background: #fff; }
  .l-main--page {
    padding: 40px 0 100px; }

@media only screen and (max-width: 767px) {
  .l-main--page {
    padding: 30px 0 80px; } }

/* ========== page head ========== */
.l-pagehead {
  position: relative;
  padding: 0 30px;
  background-image: url(../img/pagehead-bg.jpg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover; }
  .l-pagehead__inner {
    height: 295px;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    max-width: 1270px;
    margin: auto; }
  .l-pagehead .pagettl {
    color: #fff;
    font-family: "dnp-shuei-mincho-pr6n", serif;
    line-height: 1.3; }
    .l-pagehead .pagettl--en {
      font-weight: 700;
      font-size: 63px;
      text-transform: uppercase; }
    .l-pagehead .pagettl--ja {
      font-weight: 500;
      font-size: 20px; }
    .l-pagehead .pagettl span {
      display: block; }

@media only screen and (max-width: 1024px) {
  .l-pagehead .pagettl--en {
    font-size: 48px; }
  .l-pagehead .pagettl--ja {
    font-size: 16px; } }

@media only screen and (max-width: 767px) {
  .l-pagehead {
    padding: 0 16px; }
    .l-pagehead__inner {
      height: 180px; }
    .l-pagehead .pagettl--en {
      font-size: 32px; }
    .l-pagehead .pagettl--ja {
      font-size: 14px; } }

/* ========== inner ========== */
.l-inner {
  margin: auto;
  padding: 0 30px;
  max-width: 1330px; }

@media only screen and (max-width: 767px) {
  .l-inner {
    padding: 0 16px; } }

/*------------------------------------------
  header
------------------------------------------*/
.l-header {
  position: relative;
  background: #807B6F;
  z-index: 100; }
  .l-header__inner {
    padding: 0 25px;
    height: 130px;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
  .l-header__logo {
    width: 313px; }

@media only screen and (max-width: 1280px) {
  .l-header__inner {
    padding: 0 16px; }
  .l-header__logo {
    width: 280px; } }

@media only screen and (max-width: 1024px) {
  .l-header__logo {
    width: 240px; } }

@media only screen and (max-width: 767px) {
  .l-header__inner {
    height: 80px; }
  .l-header__logo {
    position: relative; }
  .l-header__trigger {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 48px;
    height: 48px;
    background: linear-gradient(135deg, #51be92 0%, #c5cf1d 100%);
    border-radius: 5px; }
    .l-header__trigger .trigger__icon {
      width: 34px;
      height: 19px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .l-header__trigger span {
      position: absolute;
      width: 100%;
      height: 3px;
      border-radius: 4px;
      background: #fff;
      left: 0;
      transition: .3s; }
      .l-header__trigger span:nth-child(1) {
        top: 0; }
      .l-header__trigger span:nth-child(2) {
        top: 50%;
        margin-top: -1.5px; }
      .l-header__trigger span:nth-child(3) {
        bottom: 0; }
    .l-header__trigger.is--active span:nth-child(1) {
      transform: translateY(8px) rotate(-45deg); }
    .l-header__trigger.is--active span:nth-child(2) {
      opacity: 0; }
    .l-header__trigger.is--active span:nth-child(3) {
      transform: translateY(-8px) rotate(45deg); } }

.l-gnav {
  letter-spacing: 0;
  -webkit-align-items: center;
  align-items: center; }
  .l-gnav__list {
    gap: 0 3em;
    -webkit-order: 1;
    -ms-order: 1;
    order: 1;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    justify-content: flex-end; }
  .l-gnav__item > a {
    color: #fff; }
  .l-gnav__sns {
    margin-left: 3em;
    gap: 0 20px;
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
    -webkit-justify-content: flex-end;
    justify-content: flex-end; }
    .l-gnav__sns img {
      vertical-align: middle;
      height: 26px; }
  .l-gnav li {
    position: relative; }
  .l-gnav a {
    display: block;
    position: relative; }
  .l-gnav__child {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    background: #fff; }

@media only screen and (max-width: 1280px) {
  .l-gnav__list {
    gap: 0 1.5em; } }

@media only screen and (max-width: 1024px) {
  .l-gnav {
    display: block;
    font-size: 14px; }
    .l-gnav__sns {
      margin: 0 0 10px; } }

@media only screen and (min-width: 768px) {
  .js-headerCont {
    display: block !important;
    opacity: 1 !important; } }

@media only screen and (max-width: 767px) {
  .l-header__cont {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background: linear-gradient(135deg, #51be92 0%, #c5cf1d 100%);
    padding: 80px 0; }
  .l-gnav__list {
    display: block;
    padding: 0 16px; }
  .l-gnav__item {
    padding: 0 1em; }
    .l-gnav__item > a {
      font-size: 24px;
      padding: .7em 0; }
  .l-gnav__child {
    position: static;
    width: 100%;
    transform: translateX(0);
    padding: 10px 0; }
    .l-gnav__child a {
      font-size: 18px;
      padding: .5em 1em; } }

/*------------------------------------------
  footer
------------------------------------------*/
.l-footer {
  padding: 96px 0 0;
  position: relative;
  z-index: 1;
  background: #fff; }
  .l-footer:before {
    content: "";
    width: 100%;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(135deg, #7fe2ba 0%, #dee389 100%); }
  .l-footer__inner {
    padding-bottom: 60px; }
  .l-footer__logo {
    width: 240px;
    margin-bottom: 40px; }
  .l-footer__cont {
    line-height: 1.43;
    font-size: 14px; }
  .l-footer__copy {
    text-align: center;
    color: #fff;
    background: #51BE92;
    font-size: 12px;
    letter-spacing: 0;
    padding: 1.8em 0; }
  .l-footer .footer-info {
    width: 55%;
    max-width: 390px;
    padding-right: 45px;
    border-right: 1px solid #51BE92; }
    .l-footer .footer-info th, .l-footer .footer-info td {
      font-weight: 500;
      vertical-align: top;
      padding-bottom: 1rem; }
    .l-footer .footer-info th {
      width: 6em; }
  .l-footer .footer-nav {
    width: 45%;
    max-width: 505px;
    padding-left: 45px;
    gap: 45px 45px; }
    .l-footer .footer-nav__box .box__ttl {
      font-family: "dnp-shuei-mincho-pr6n", serif;
      font-size: 16px;
      margin-bottom: 1.8em; }
    .l-footer .footer-nav__box .box__list li {
      margin-bottom: 0.5em; }
      .l-footer .footer-nav__box .box__list li:last-child {
        margin-bottom: 0; }

@media only screen and (max-width: 767px) {
  .l-footer {
    padding: 80px 0 10.76923vw; }
    .l-footer__inner {
      padding-bottom: 48px; }
    .l-footer__copy {
      padding: 1.8em 0; }
    .l-footer .footer-info {
      width: 100%;
      max-width: 100%;
      padding-right: 0;
      border-right: none;
      margin-bottom: 48px; }
    .l-footer .footer-nav {
      width: 100%;
      max-width: 100%;
      padding-left: 0;
      gap: 32px 0; }
      .l-footer .footer-nav__box {
        width: 100%; } }

.l-guide {
  background: #7DC3C0;
  position: relative;
  z-index: 1; }
  .l-guide__cont {
    width: 50%; }
  .l-guide__cont--recruit {
    text-align: center;
    padding: 48px 30px;
    background: linear-gradient(0deg, #51be92 0%, #21b8d7 100%); }
    .l-guide__cont--recruit .recruit-cont {
      max-width: 562px;
      margin: auto;
      position: relative; }
      .l-guide__cont--recruit .recruit-cont__img {
        position: relative;
        margin-bottom: 55px; }
        .l-guide__cont--recruit .recruit-cont__img:before, .l-guide__cont--recruit .recruit-cont__img:after {
          content: "";
          position: absolute; }
        .l-guide__cont--recruit .recruit-cont__img:before {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.2); }
        .l-guide__cont--recruit .recruit-cont__img:after {
          width: 100%;
          height: 32px;
          background: url(../img/guide-recruit-ttl.svg) no-repeat center;
          background-size: auto 100%;
          left: 0;
          bottom: 0;
          transform: translateY(50%); }
        .l-guide__cont--recruit .recruit-cont__img img {
          border-radius: 10px; }
      .l-guide__cont--recruit .recruit-cont__txt {
        color: #fff; }
      .l-guide__cont--recruit .recruit-cont__more a {
        display: inline-block;
        position: relative;
        background: #fff;
        color: #51BE92;
        font-size: 18px;
        line-height: 34px;
        border-radius: 10px;
        width: 182px;
        margin-right: 15px; }
        .l-guide__cont--recruit .recruit-cont__more a:after {
          content: "▶︎";
          position: absolute;
          font-size: 12px;
          top: 0;
          right: -18px;
          color: #fff; }
  .l-guide__cont--info .p-hour {
    background: #F9F9F9;
    padding: 50px 25px; }
    .l-guide__cont--info .p-hour__inner {
      max-width: 610px; }
    .l-guide__cont--info .p-hour table tr:first-child {
      border-color: #51BE92; }
    .l-guide__cont--info .p-hour table th {
      border-color: #C5CF1D; }
  .l-guide__cont--info .p-access {
    padding: 40px 25px; }

@media only screen and (max-width: 767px) {
  .l-guide__cont {
    width: 100%; }
  .l-guide__cont--recruit {
    padding: 32px 16px 48px; }
    .l-guide__cont--recruit .recruit-cont {
      max-width: 100%; }
      .l-guide__cont--recruit .recruit-cont__img {
        margin-bottom: 48px; }
      .l-guide__cont--recruit .recruit-cont__txt {
        font-size: 15px; }
  .l-guide__cont--info .p-hour {
    padding: 32px 16px; }
    .l-guide__cont--info .p-hour__inner {
      max-width: 100%; }
  .l-guide__cont--info .p-access {
    padding: 32px 16px; } }

.l-access {
  text-align: center;
  position: relative;
  z-index: 1;
  background: #F7F7F7; }
  .l-access__inner {
    position: relative; }
    .l-access__inner:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../img/access-bg.png);
      background-repeat: no-repeat;
      background-position: left top;
      background-size: auto 100%;
      z-index: 1; }
  .l-access__cont {
    width: 50%;
    padding: 60px 48px 64px;
    position: relative; }
  .l-access__cont--view {
    background: #F9F9F9; }
  .l-access__ttl {
    position: relative;
    z-index: 2;
    margin-bottom: 45px; }
    .l-access__ttl span {
      display: block; }
    .l-access__ttl .ttl--en img {
      width: auto;
      height: 21px; }
    .l-access__ttl .ttl--ja {
      font-family: "dnp-shuei-mincho-pr6n", serif;
      font-size: 18px;
      letter-spacing: .16em;
      margin-top: 1.3em; }
  .l-access .access-iframe {
    position: relative;
    z-index: 2; }
    .l-access .access-iframe iframe {
      width: 100%;
      height: 326px; }

@media only screen and (max-width: 1024px) {
  .l-access__inner {
    padding: 0 30px;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
  .l-access__cont {
    width: 48%;
    padding: 60px 0 64px; } }

@media only screen and (max-width: 767px) {
  .l-access__inner {
    padding: 0 16px; }
    .l-access__inner:before {
      background-image: url(../img/access-bg-sp.png); }
  .l-access__cont {
    width: 100%;
    padding: 32px 0; }
  .l-access__ttl {
    margin-bottom: 24px; }
  .l-access .access-iframe iframe {
    height: 200px; } }

.l-fixnav {
  position: fixed;
  z-index: 99;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 48px; }
  .l-fixnav a {
    display: block; }
  .l-fixnav img {
    width: 100%; }

@media only screen and (max-width: 767px) {
  .l-fixnav {
    top: auto;
    bottom: 0;
    transform: translateY(0);
    width: 100%; }
    .l-fixnav__list {
      display: flex; }
    .l-fixnav__item--tel {
      width: 54.87%; }
    .l-fixnav__item--web {
      width: 34.35%; }
    .l-fixnav__item--pagetop {
      width: 10.77%; } }

/*------------------------------------------
  project
------------------------------------------*/
.p-hour {
  padding: 30px; }
  .p-hour__inner {
    max-width: 560px;
    margin: auto; }
  .p-hour table {
    table-layout: fixed;
    font-family: "dnp-shuei-mincho-pr6n", serif;
    line-height: 1.5; }
    .p-hour table tr:first-child {
      border-bottom: 1px solid #FABDBF; }
      .p-hour table tr:first-child th {
        font-size: 18px; }
    .p-hour table th {
      text-align: center;
      width: 160px;
      border-right: 1px solid #F0CB94;
      padding: .5em 0; }
    .p-hour table td {
      text-align: center;
      padding: .5em 0; }
  .p-hour .hour__txt {
    font-size: 14px;
    line-height: 1.8;
    margin: 1em 0 0 32px; }

@media only screen and (max-width: 1024px) {
  .p-hour {
    padding: 30px 20px; }
    .p-hour table {
      font-size: 14px; }
      .p-hour table tr:first-child th {
        font-size: 14px; }
      .p-hour table th {
        width: 115px; }
    .p-hour .hour__txt {
      margin-left: 15px; } }

@media only screen and (max-width: 767px) {
  .p-hour {
    padding: 30px 16px 48px; }
    .p-hour__inner {
      max-width: 100%; }
    .p-hour table th {
      width: 106px; }
    .p-hour .hour__txt {
      margin-left: 10px; } }

.p-access {
  padding: 30px;
  color: #fff;
  line-height: 1.5; }
  .p-access__inner {
    max-width: 560px;
    margin: auto; }
  .p-access p {
    margin-bottom: 0; }
  .p-access .access__ttl {
    display: inline-block;
    font-size: 20px;
    font-family: "dnp-shuei-mincho-pr6n", serif;
    border-bottom: 1px solid #fff;
    padding-bottom: .5em;
    margin-bottom: 1em; }
  .p-access .access__txt p {
    padding-left: 35px;
    margin-bottom: .5em; }
  .p-access .access__txt .ico {
    position: relative;
    font-size: 18px;
    background-repeat: no-repeat;
    background-position: left top; }
    .p-access .access__txt .ico-train {
      background-image: url(../img/ico-train.svg);
      background-size: 22px; }
  .p-access .access__txt .more {
    margin-top: 1.5em; }

@media only screen and (max-width: 1024px) {
  .p-access {
    padding: 30px 20px; } }

@media only screen and (max-width: 767px) {
  .p-access {
    padding: 16px; }
    .p-access__inner {
      max-width: 100%; }
    .p-access .access__ttl {
      font-size: 16px; }
    .p-access .access__txt p {
      font-size: 12px;
      padding-left: 20px; }
    .p-access .access__txt .ico {
      font-size: 14px; }
      .p-access .access__txt .ico-train {
        background-size: 14px; }
    .p-access .access__txt .more {
      margin-top: 1em;
      font-size: 14px; } }

/*------------------------------------------
  home
------------------------------------------*/
/* ========== header ========== */
body.home .l-header {
  position: fixed;
  top: 0 !important;
  right: 0;
  background: linear-gradient(135deg, #51be92 0%, #c5cf1d 100%);
  border-radius: 0 0 0 20px / 0 0 0 20px; }
  body.home .l-header__inner {
    height: 64px;
    padding: 0 48px; }
  body.home .l-header__logo {
    display: none; }

@media only screen and (max-width: 1024px) {
  body.home .l-gnav {
    display: flex; }
    body.home .l-gnav__sns {
      margin: 0 0 0 1.5em; } }

@media only screen and (max-width: 767px) {
  body.home .l-header {
    background: none; }
    body.home .l-header__inner {
      height: 80px;
      padding: 0 16px; } }

/* ========== main ========== */
body.home .l-main {
  margin-top: 100vh;
  margin-top: 100dvh;
  background: none; }

/* ========== mv ========== */
.p-mv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  padding: 24px; }
  .p-mv__logo {
    position: absolute;
    top: 48px;
    left: 48px;
    width: 240px;
    z-index: 1; }
  .p-mv__lead {
    position: absolute;
    top: 138px;
    right: 96px;
    z-index: 1;
    display: flex;
    gap: 0 32px; }
    .p-mv__lead .leadtxt {
      margin: 0;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      font-family: "dnp-shuei-mincho-pr6n", serif;
      letter-spacing: .24em; }
      .p-mv__lead .leadtxt--main {
        font-size: 30px;
        -webkit-order: 2;
        -ms-order: 2;
        order: 2; }
      .p-mv__lead .leadtxt--sub {
        font-size: 24px;
        -webkit-order: 1;
        -ms-order: 1;
        order: 1; }
      .p-mv__lead .leadtxt span {
        padding: 1em .25em .5em;
        background: #fff;
        display: inline-block;
        border-radius: 0 0 5px 5px / 0 0 5px 5px;
        position: relative; }
        .p-mv__lead .leadtxt span:before {
          content: "";
          width: 100%;
          height: 4px;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(0deg, #fabdbf 0%, #f0cb94 100%); }
  .p-mv__catch {
    position: absolute;
    bottom: 52px;
    left: 52px;
    z-index: 1;
    font-family: "dnp-shuei-mincho-pr6n", serif;
    text-align: center;
    background: #fff;
    width: 275px;
    line-height: 36px;
    font-size: 20px;
    letter-spacing: .24em;
    margin: 0;
    border-radius: 10px; }
    .p-mv__catch:before, .p-mv__catch:after {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100%; }
    .p-mv__catch:before {
      top: -4px;
      left: -4px;
      background-image: url(../img/mv-catch-left.svg); }
    .p-mv__catch:after {
      right: -4px;
      bottom: -4px;
      background-image: url(../img/mv-catch-right.svg); }
  .p-mv__slider {
    position: relative;
    width: 100%;
    height: 100%; }
    .p-mv__slider .slideitem {
      width: 100%;
      height: calc(100vh - 48px);
      position: relative; }
      .p-mv__slider .slideitem:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(84, 84, 84, 0.2) 0%, rgba(68, 68, 68, 0.1) 20%, rgba(0, 0, 0, 0.1) 70%, rgba(0, 0, 0, 0.4) 100%);
        border-radius: 20px; }
      .p-mv__slider .slideitem span {
        height: 100%; }
        .p-mv__slider .slideitem span.pc {
          display: block; }
      .p-mv__slider .slideitem img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px; }
  .p-mv__scroll {
    position: absolute;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 56px;
    animation: scrollDown 2s infinite;
    margin-bottom: 0; }

@keyframes scrollDown {
  0% {
    margin-bottom: 0; }
  50% {
    margin-bottom: 10px; }
  100% {
    margin-bottom: 0; } }

@media only screen and (max-width: 767px) {
  .p-mv {
    padding: 0; }
    .p-mv__logo {
      top: 16px;
      left: 16px;
      width: 180px; }
    .p-mv__lead {
      top: auto;
      bottom: 29.48718vw;
      right: auto;
      left: 16px;
      display: block; }
      .p-mv__lead .leadtxt {
        -ms-writing-mode: tb-lr;
        writing-mode: horizontal-tb; }
        .p-mv__lead .leadtxt--main {
          font-size: 6.15385vw;
          margin-bottom: 3.07692vw; }
        .p-mv__lead .leadtxt--sub {
          font-size: 4.10256vw;
          letter-spacing: .36em; }
        .p-mv__lead .leadtxt span {
          padding: .3em .5em;
          border-radius: 0 1.28205vw 1.28205vw 0/0 1.28205vw 1.28205vw 0; }
          .p-mv__lead .leadtxt span:before {
            width: 0.76923vw;
            height: 100%; }
    .p-mv__catch {
      bottom: 15.38462vw;
      left: 18px;
      width: 51.79487vw;
      line-height: 7.69231vw;
      font-size: 3.58974vw;
      border-radius: 2.5641vw; }
      .p-mv__catch:before, .p-mv__catch:after {
        width: 4.10256vw;
        height: 4.10256vw; }
      .p-mv__catch:before {
        top: -0.51282vw;
        left: -0.51282vw; }
      .p-mv__catch:after {
        right: -0.51282vw;
        bottom: -0.51282vw; }
    .p-mv__slider .slideitem {
      height: 100vh; }
      .p-mv__slider .slideitem:before {
        border-radius: 0; }
      .p-mv__slider .slideitem img {
        border-radius: 0; }
    .p-mv__scroll {
      display: none; } }

/* ========== home common ========== */
.p-home {
  margin-bottom: 0; }
  .p-home__ttl {
    text-align: center; }
    .p-home__ttl span {
      display: block; }
    .p-home__ttl .ttl--img img {
      height: 32px;
      width: auto; }
    .p-home__ttl .ttl--txt {
      letter-spacing: .16em;
      font-size: 24px;
      font-family: "dnp-shuei-mincho-pr6n", serif;
      margin-top: 1.5em; }
      .p-home__ttl .ttl--txt strong {
        font-size: 32px; }

@media only screen and (max-width: 767px) {
  .p-home__ttl .ttl--img img {
    height: 6.66667vw;
    max-height: 26px; }
  .p-home__ttl .ttl--txt {
    margin-top: .8em; } }

/* ========== announce ========== */
.p-announce {
  background: #F9F9F9; }
  .p-announce.elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 100% !important;
    padding: 0 !important; }
  .p-announce .p-home__ttl .ttl--img img {
    height: 22px; }
  .p-announce .p-home__ttl .ttl--txt {
    font-size: 18px; }
  .p-announce--news {
    width: 52% !important;
    padding: 95px 0 20px 48px; }
    .p-announce--news:before {
      content: "";
      width: 48px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(135deg, #7fe2ba 0%, #dee389 100%); }
    .p-announce--news .p-home__ttl {
      margin-bottom: 45px !important; }
  .p-announce--information {
    width: 48% !important;
    padding: 95px 0 0;
    background: #fff; }
    .p-announce--information .p-home__ttl {
      margin-bottom: 0 !important; }
  .p-announce .newslist {
    padding: 0 30px; }
    .p-announce .newslist__list {
      max-width: 580px;
      height: 240px;
      margin: auto; }
      .p-announce .newslist__list ul {
        padding-right: 16px; }
      .p-announce .newslist__list li {
        margin-bottom: 20px; }
        .p-announce .newslist__list li:last-child {
          margin-bottom: 0; }
        .p-announce .newslist__list li:nth-child(2n) .item__date {
          border-color: #C5CF1D; }
      .p-announce .newslist__list a {
        display: block;
        font-size: 18px;
        line-height: 1.5; }
      .p-announce .newslist__list .item__date {
        font-family: "dnp-shuei-mincho-pr6n", serif;
        padding-bottom: .3em;
        margin-bottom: .5em;
        border-bottom: 1px solid #51BE92; }
      .p-announce .newslist__list .item__ttl {
        margin-bottom: 0; }
    .p-announce .newslist__more {
      text-align: center;
      margin-top: 48px; }
  .p-announce .p-access {
    background-image: url(../img/home-information-bg.png);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover; }

.jspContainer {
  overflow: hidden;
  position: relative; }

.jspPane {
  position: absolute; }

.jspVerticalBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 100%; }

.jspCap {
  display: none; }

.jspHorizontalBar .jspCap {
  float: left; }

.jspTrack {
  background: #C5CF1D;
  position: relative; }

.jspDrag {
  background: #51BE92;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer; }

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag {
  float: left;
  height: 100%; }

@media only screen and (max-width: 1024px) {
  .p-announce .newslist {
    padding: 0 20px; } }

@media only screen and (max-width: 767px) {
  .p-announce .p-home__ttl .ttl--img img {
    height: 5.64103vw;
    max-height: 22px; }
  .p-announce--news {
    width: 100% !important;
    padding: 64px 16px 48px; }
    .p-announce--news:before {
      width: 100%;
      height: 16px;
      background: linear-gradient(135deg, #dee389 0%, #7fe2ba 100%); }
  .p-announce--information {
    width: 100% !important;
    padding: 48px 0 0; }
    .p-announce--information .p-home__ttl {
      margin-bottom: 0 !important; }
  .p-announce .newslist {
    padding: 0; }
    .p-announce .newslist__list {
      max-width: 100%;
      height: 228px; }
      .p-announce .newslist__list a {
        font-size: 16px; }
    .p-announce .newslist__more {
      margin-top: 32px; }
  .p-announce .p-access {
    background-position: center; } }

/* ========== bnr ========== */
.p-bnr {
  padding: 64px 0;
  background: linear-gradient(0deg, rgba(33, 184, 215, 0.5) 0%, rgba(81, 190, 146, 0.5) 100%);
  backdrop-filter: blur(50px);
  text-align: center; }
  .p-bnr .elementor-image img {
    width: 634px; }

@media only screen and (max-width: 767px) {
  .p-bnr {
    padding: 40px 0; } }

/* ========== features ========== */
.p-features {
  padding: 85px 0 95px;
  background-color: #fff;
  background-image: url(../img/home-features-bg.png);
  background-repeat: no-repeat;
  background-position: center top 125px;
  background-size: 1366px; }
  .p-features .p-home__ttl {
    margin-bottom: 90px !important; }

@media only screen and (max-width: 767px) {
  .p-features {
    overflow: hidden;
    padding: 55px 0 64px;
    background-image: url(../img/home-features-bg-sp.png);
    background-position: center top 150px;
    background-size: 100%; }
    .p-features.elementor-section.elementor-section-boxed > .elementor-container {
      padding: 0; }
    .p-features .p-home__ttl {
      margin-bottom: 85px !important; } }

.features-item {
  position: relative; }
  .features-item__wrapper {
    -webkit-justify-content: space-between;
    justify-content: space-between; }
  .features-item__inner {
    position: relative;
    -webkit-align-items: flex-start;
    align-items: flex-start; }
  .features-item__img {
    position: relative; }
    .features-item__img:before, .features-item__img:after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 42px;
      height: 42px;
      background-repeat: no-repeat;
      background-size: 100%; }
    .features-item__img:before {
      top: -13px;
      left: -13px; }
    .features-item__img:after {
      bottom: -13px;
      right: -13px; }
  .features-item__txt {
    padding: 24px 20px 0; }
    .features-item__txt .itemtxt--ttl {
      font-family: "dnp-shuei-mincho-pr6n", serif;
      font-size: 24px;
      line-height: 1.333;
      font-weight: 700;
      margin-bottom: .5em; }
    .features-item__txt .itemtxt--txt {
      font-weight: 400;
      margin-bottom: 0;
      letter-spacing: 0; }
    .features-item__txt .itemtxt--more {
      margin-top: 1em; }
  .features-item__more {
    position: absolute;
    right: 13px;
    bottom: 0; }
  .features-item .itemtxt--more a {
    display: inline-block;
    font-weight: 700;
    font-size: 18px; }
  .features-item .label {
    position: absolute;
    top: -55px;
    right: 30px;
    z-index: 1;
    text-align: center;
    font-weight: 700;
    line-height: 1;
    font-size: 16px;
    letter-spacing: 0; }
    .features-item .label .num {
      display: block;
      font-family: "dnp-shuei-mincho-pr6n", serif;
      font-size: 79px;
      letter-spacing: -.05em; }
  .features-item--first {
    margin-bottom: 120px; }
    .features-item--first .features-item__img {
      width: 62%;
      padding-left: 10px; }
    .features-item--first .features-item__txt {
      width: 36%;
      margin-left: auto; }
      .features-item--first .features-item__txt .itemtxt--ttl {
        font-size: 30px; }
      .features-item--first .features-item__txt .itemtxt--more a {
        font-size: 14px; }
    .features-item--first .label {
      position: static;
      font-size: 13px;
      display: inline-block;
      margin-bottom: 40px; }
      .features-item--first .label .num {
        font-size: 61px; }
      .features-item--first .label.sp {
        display: none; }
  .features-item--col {
    width: 33%;
    max-width: 390px;
    padding: 0 13px 45px; }
    .features-item--col:before {
      content: "";
      width: 100%;
      height: 438px;
      position: absolute;
      left: 0;
      top: 120px; }
  .features-item01 .features-item__img:before, .features-item01 .features-item__img:after {
    width: 32px;
    height: 32px; }
  .features-item01 .features-item__img:before {
    top: -10px;
    left: 0;
    background-image: url(../img/features-img01-arrow01.png); }
  .features-item01 .features-item__img:after {
    bottom: -10px;
    right: -10px;
    background-image: url(../img/features-img01-arrow02.png); }
  .features-item01 .itemtxt--ttl {
    color: #4BAC85; }
  .features-item01 .itemtxt--more a {
    color: #51BE92; }
  .features-item01 .label {
    color: #51BE92; }
  .features-item02:before {
    background: linear-gradient(0deg, rgba(247, 243, 237, 0) 0%, rgba(240, 203, 148, 0.5) 50%, #fabdbf 100%); }
  .features-item02 .features-item__img:before {
    background-image: url(../img/features-img02-arrow.png); }
  .features-item02 .itemtxt--ttl {
    color: #E89C9F; }
  .features-item02 .itemtxt--more a {
    color: #E89C9F; }
  .features-item02 .label {
    color: #FABDBF; }
  .features-item03:before {
    background: linear-gradient(0deg, rgba(249, 249, 249, 0) 0%, rgba(197, 207, 29, 0.25) 50%, rgba(81, 190, 146, 0.5) 100%); }
  .features-item03 .features-item__img:before {
    background-image: url(../img/features-img03-arrow.png); }
  .features-item03 .itemtxt--ttl {
    color: #4BAC85; }
  .features-item03 .itemtxt--more a {
    color: #51BE92; }
  .features-item03 .label {
    color: #51BE92; }
  .features-item04:before {
    background: linear-gradient(0deg, rgba(247, 243, 237, 0) 0%, rgba(240, 203, 148, 0.5) 50%, #fabdbf 100%); }
  .features-item04 .features-item__img:before {
    background-image: url(../img/features-img04-arrow.png); }
  .features-item04 .itemtxt--ttl {
    color: #E89C9F; }
  .features-item04 .itemtxt--more a {
    color: #E89C9F; }
  .features-item04 .label {
    color: #FABDBF; }

@media only screen and (max-width: 1024px) {
  .features-item__txt {
    padding: 24px 0 0; }
  .features-item--first .features-item__img {
    width: 100%; }
  .features-item--first .features-item__txt {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .features-item__wrapper {
    gap: 90px 0; }
  .features-item__img:before, .features-item__img:after {
    width: 16px;
    height: 16px; }
  .features-item__img:before {
    top: -4px;
    left: -4px; }
  .features-item__img:after {
    bottom: -4px;
    right: -4px; }
  .features-item__txt {
    padding: 32px 0 0; }
    .features-item__txt .itemtxt--ttl {
      font-family: "dnp-shuei-mincho-pr6n", serif;
      font-size: 24px;
      line-height: 1.333;
      font-weight: 700;
      margin-bottom: .5em; }
    .features-item__txt .itemtxt--txt {
      font-weight: 400;
      margin-bottom: 0;
      letter-spacing: 0; }
    .features-item__txt .itemtxt--more {
      margin-top: 1.5em; }
  .features-item__more {
    position: static; }
  .features-item .itemtxt--more a {
    font-size: 14px; }
  .features-item .label {
    top: -50px;
    right: 30px; }
    .features-item .label .num {
      font-size: 72px; }
  .features-item--first {
    margin-bottom: 80px; }
    .features-item--first .features-item__img {
      width: 100%;
      padding-left: 0; }
    .features-item--first .features-item__txt {
      width: 100%;
      padding: 24px 16px 0; }
      .features-item--first .features-item__txt .itemtxt--ttl {
        font-size: 30px; }
    .features-item--first .label {
      position: absolute;
      margin-bottom: 0;
      top: -37px;
      right: auto;
      left: 23px;
      display: inline-block !important; }
  .features-item--col {
    width: 100%;
    max-width: 100%;
    padding: 0 16px; }
    .features-item--col:before {
      height: 65%;
      top: 30.76923vw; }
  .features-item01 .features-item__img:before, .features-item01 .features-item__img:after {
    width: 16px;
    height: 16px; }
  .features-item01 .features-item__img:before {
    top: 0; }
  .features-item01 .features-item__img:after {
    bottom: 0;
    right: 0; } }

/* ========== medical ========== */
.p-medical {
  padding: 95px 0;
  background: #F9F9F9; }
  .p-medical.elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 100% !important;
    padding: 0 !important; }
  .p-medical .p-home__ttl {
    margin-bottom: 60px !important; }
  .p-medical .medicallist {
    padding: 0 30px;
    position: relative; }
    .p-medical .medicallist:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, #eef2af 0%, #a0f0d0 100%); }
    .p-medical .medicallist ul {
      max-width: 1270px;
      margin: auto;
      position: relative; }
    .p-medical .medicallist li {
      width: 25%; }

@media only screen and (max-width: 767px) {
  .p-medical {
    padding: 64px 0; }
    .p-medical .p-home__ttl {
      margin-bottom: 50px !important; }
    .p-medical .medicallist {
      padding: 0 16px; }
      .p-medical .medicallist:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, #eef2af 0%, #a0f0d0 100%); }
      .p-medical .medicallist li {
        width: 50%; } }

/* ========== greeting ========== */
.p-greeting {
  padding: 64px 0 96px;
  background: linear-gradient(45deg, white 0%, #fefff2 50%, #f2fffa 100%); }
  .p-greeting > .elementor-container {
    background: url(../img/home-greeting-ttl.svg) no-repeat left top;
    background-size: 42px; }

@media only screen and (max-width: 767px) {
  .p-greeting {
    padding: 48px 0; }
    .p-greeting > .elementor-container {
      background: none; }
    .p-greeting .greeting-ttl {
      margin-bottom: 48px; }
      .p-greeting .greeting-ttl img {
        height: 8.20513vw;
        max-height: 32px; } }

.greeting-item {
  margin-bottom: 96px;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .greeting-item:last-child {
    margin-bottom: 0; }
  .greeting-item__txt {
    width: 48%;
    padding-left: 106px; }
  .greeting-item__img {
    width: 48%;
    padding: 0 16px;
    position: relative; }
  .greeting-item .item__head {
    margin-bottom: 48px;
    position: relative;
    overflow: hidden;
    font-size: 24px;
    letter-spacing: .16em;
    font-family: "dnp-shuei-mincho-pr6n", serif;
    line-height: 1; }
    .greeting-item .item__head span {
      display: inline-block;
      position: relative;
      padding-right: 16px;
      border-right: 1px solid #333; }
      .greeting-item .item__head span:after {
        content: "";
        width: 200%;
        height: 1px;
        background: #333;
        position: absolute;
        top: 50%;
        left: 100%; }
  .greeting-item .item__txt p {
    margin-bottom: 0;
    line-height: 2.13;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0; }
  .greeting-item .item__name {
    position: absolute;
    right: 0;
    top: 42px;
    z-index: 1;
    line-height: 1;
    font-size: 18px;
    font-family: "dnp-shuei-mincho-pr6n", serif;
    letter-spacing: 0.16em;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl; }
    .greeting-item .item__name p {
      margin-bottom: 0;
      margin-left: 8px; }
      .greeting-item .item__name p:last-child {
        margin-top: 16px;
        margin-left: 0; }
    .greeting-item .item__name span {
      display: inline-block;
      background: #fff;
      padding: 1em .5em; }
  .greeting-item .item__img {
    position: relative; }
    .greeting-item .item__img:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: -16px;
      bottom: -16px;
      background: #000; }
    .greeting-item .item__img img {
      position: relative; }
    .greeting-item .item__img.green:before {
      background: linear-gradient(315deg, rgba(197, 207, 29, 0.4) 0%, rgba(81, 190, 146, 0.4) 100%); }
    .greeting-item .item__img.pink:before {
      background: linear-gradient(315deg, rgba(250, 189, 191, 0.4) 0%, rgba(240, 203, 148, 0.4) 100%); }
  .greeting-item .item__more {
    margin-top: 64px;
    text-align: center; }

@media only screen and (max-width: 1024px) {
  .greeting-item__txt {
    padding-left: 50px; } }

@media only screen and (max-width: 767px) {
  .greeting-item {
    margin-bottom: 48px; }
    .greeting-item__txt {
      width: 100%;
      padding-left: 0;
      -webkit-order: 2;
      -ms-order: 2;
      order: 2; }
    .greeting-item__img {
      width: 100%;
      padding: 0;
      margin-bottom: 48px;
      -webkit-order: 1;
      -ms-order: 1;
      order: 1; }
    .greeting-item .item__head {
      margin-bottom: 32px; }
    .greeting-item .item__name {
      top: 24px;
      font-size: 16px; }
      .greeting-item .item__name p:last-child {
        margin-top: 8px; }
    .greeting-item .item__more {
      margin-top: 32px; } }

/* ========== btn ========== */
.p-btn {
  padding: 64px 0;
  background: linear-gradient(315deg, rgba(33, 184, 215, 0.5) 0%, rgba(81, 190, 146, 0.5) 100%);
  backdrop-filter: blur(50px); }
  .p-btn .btnlist {
    max-width: 1010px;
    gap: 20px 0;
    margin: auto;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
    .p-btn .btnlist a {
      display: block;
      width: 49%;
      max-width: 472px;
      text-align: center;
      position: relative;
      padding: 4px;
      color: #21B8D7;
      font-size: 32px;
      line-height: 1.5;
      background: linear-gradient(315deg, #21b8d7 0%, #51be92 100%); }
      .p-btn .btnlist a:nth-child(2n) {
        color: #51BE92;
        background: linear-gradient(315deg, #51be92 0%, #21b8d7 100%); }
      .p-btn .btnlist a:after {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        bottom: 12px;
        right: 12px;
        background: url(../img/home-btn-arrow.svg) no-repeat;
        background-size: 100%; }
    .p-btn .btnlist .inner {
      background: #fff;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      height: 92px;
      border-radius: 10px;
      background-position: left 20px center;
      background-repeat: no-repeat; }
    .p-btn .btnlist .btn--faq .inner {
      background-image: url(../img/home-btn-faq.svg);
      background-size: 66px; }
    .p-btn .btnlist .btn--price .inner {
      background-image: url(../img/home-btn-price.svg);
      background-size: 52px; }

@media only screen and (max-width: 1024px) {
  .p-btn .btnlist a {
    font-size: 22px; }
  .p-btn .btnlist .inner span {
    padding-left: 40px; } }

@media only screen and (max-width: 767px) {
  .p-btn {
    padding: 32px 0; }
    .p-btn .btnlist {
      gap: 32px 0; }
      .p-btn .btnlist a {
        width: 100%;
        max-width: 100%;
        padding: 4px 8px;
        font-size: 24px; }
        .p-btn .btnlist a:after {
          bottom: 8px; }
      .p-btn .btnlist .inner {
        background-position: left 14px center; }
      .p-btn .btnlist .btn--faq .inner {
        background-size: 57px; }
      .p-btn .btnlist .btn--price .inner {
        background-size: 52px; } }

/* ========== loop ========== */
.p-loop {
  background-color: #F9F9F9;
  background-image: url(../img/home-loop-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1366px;
  padding: 32px 0; }
  .p-loop.elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 100% !important;
    padding: 0 !important; }
  .p-loop .slideitem {
    padding: 0 15px; }
    .p-loop .slideitem:nth-child(2n) {
      padding-top: 32px; }

@media only screen and (max-width: 767px) {
  .p-loop {
    background-image: url(../img/home-loop-bg-sp.png);
    background-size: 100%;
    padding: 32px 0; }
    .p-loop .slideitem {
      padding: 0 8px; }
      .p-loop .slideitem:nth-child(2n) {
        padding-top: 16px; } }

/* ========== contact ========== */
.p-contact {
  background: #fff; }
  .p-contact:before, .p-contact:after {
    content: "";
    position: absolute;
    top: 0;
    width: 48px;
    height: 100%; }
  .p-contact:before {
    left: 0;
    background: linear-gradient(135deg, #f0cb94 0%, #fabdbf 100%); }
  .p-contact:after {
    right: 0;
    background: linear-gradient(135deg, #fabdbf 0%, #f0cb94 100%); }
  .p-contact.elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 100% !important;
    padding: 0 48px !important; }
  .p-contact .elementor-row {
    -webkit-align-items: center;
    align-items: center; }
  .p-contact__head {
    padding: 30px 58px; }
  .p-contact .contact__ttl {
    border-bottom: 1px solid #FABDBF;
    padding-bottom: 16px;
    margin-bottom: 35px !important; }
  .p-contact .contact__txt p {
    margin-bottom: 0;
    font-weight: 400; }
  .p-contact .contact__btnlist a {
    display: block;
    width: 50%;
    text-align: center;
    position: relative;
    padding: 56px 16px 0;
    min-height: 324px;
    color: #fff;
    font-family: "dnp-shuei-mincho-pr6n", serif;
    line-height: 1.5; }
    .p-contact .contact__btnlist a:after {
      content: "";
      position: absolute;
      bottom: 8px;
      right: 8px;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      border-width: 0px 0px 16px 16px; }
  .p-contact .contact__btnlist .btnlist__btn--tel {
    background: #E89C9F; }
  .p-contact .contact__btnlist .btnlist__btn--web {
    background: #EBC386; }
  .p-contact .contact__btnlist .btnlist__icon img {
    width: 120px; }
  .p-contact .contact__btnlist .btnlist__ttl {
    margin-top: 1em;
    font-size: 28px;
    margin-bottom: 0; }
  .p-contact .contact__btnlist .btnlist__txt {
    margin-top: 1em;
    margin-bottom: 0; }

@media only screen and (max-width: 1024px) {
  .p-contact {
    background: #fff; }
    .p-contact:before, .p-contact:after {
      width: 30px; }
    .p-contact.elementor-section.elementor-section-boxed > .elementor-container {
      padding: 0 30px !important; }
    .p-contact__head {
      padding: 30px 20px; }
    .p-contact .contact__btnlist .btnlist__ttl {
      font-size: 21px; } }

@media only screen and (max-width: 767px) {
  .p-contact {
    background: #fff; }
    .p-contact:before, .p-contact:after {
      width: 100%;
      height: 16px; }
    .p-contact:after {
      top: auto;
      bottom: 0; }
    .p-contact.elementor-section.elementor-section-boxed > .elementor-container {
      padding: 16px 0 !important; }
    .p-contact__head {
      padding: 32px 16px; }
    .p-contact .contact__ttl {
      margin-bottom: 30px !important; }
      .p-contact .contact__ttl img {
        width: 335px; }
    .p-contact .contact__btnlist a {
      width: 100%;
      height: auto;
      padding: 56px 16px; }
    .p-contact .contact__btnlist .btnlist__ttl {
      font-size: 28px; } }
