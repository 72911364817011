@charset "utf-8";
.l-header {
    position: relative;
    background: #807B6F;
    z-index: 100;
    &__inner {
        padding: 0 25px;
        height: 130px;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    &__logo {
        width: 313px;
    }
}
@media only screen and (max-width: 1280px) {
.l-header {
    &__inner {
        padding: 0 16px;
    }
    &__logo {
        width: 280px;
    }
}
}
@media only screen and (max-width: 1024px) {
.l-header {
    &__logo {
        width: 240px;
    }
}
}
@media only screen and (max-width: 767px) {
.l-header {
    &__inner {
        height: 80px;
    }
    &__logo {
        position: relative;
    }
    &__trigger {
        z-index: 10;
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
        width: 48px;
        height: 48px;
        background: linear-gradient(135deg, rgba(#51BE92,1) 0%, rgba(#C5CF1D,1) 100%);
        border-radius: 5px;
        .trigger__icon {
            width: 34px;
            height: 19px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        span {
            position: absolute;
            width: 100%;
            height: 3px;
            border-radius: 4px;
            background: #fff;
            left: 0;
            transition: .3s;
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2) {
                top: 50%;
                margin-top: -1.5px;
            }
            &:nth-child(3) {
                bottom: 0;
            }
        }
        &.is--active {
            span {
                &:nth-child(1) {
                    transform: translateY(8px) rotate(-45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:nth-child(3) {
                    transform: translateY(-8px) rotate(45deg);
                }
            }
        }
    }
}
}

.l-gnav {
    letter-spacing: 0;
    -webkit-align-items: center;
            align-items: center;
    &__list {
        gap: 0 3em;
        -webkit-order: 1;
            -ms-order: 1;
                order: 1;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }
    &__item {
        >a {
            color: #fff;
        }
    }
    &__sns {
        margin-left: 3em;
        gap: 0 20px;
        -webkit-order: 2;
            -ms-order: 2;
                order: 2;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        img {
            vertical-align: middle;
            height: 26px;
        }
    }
    li {
        position: relative;
    }
    a {
        display: block;
        position: relative;
    }
    &__child {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        background: #fff;
    }
}
@media only screen and (max-width: 1280px) {
.l-gnav {
    &__list {
        gap: 0 1.5em;
    }
}
}
@media only screen and (max-width: 1024px) {
.l-gnav {
    display: block;
    font-size: 14px;
    &__sns {
        margin: 0 0 10px;
    }
}
}
@media only screen and (min-width: 768px) {
.js-headerCont {
    display: block!important;
    opacity: 1!important;
}
}
@media only screen and (max-width: 767px) {
.l-header {
    &__cont {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        width: 100%;
        height: 100vh;
        height: 100dvh;
        background: linear-gradient(135deg, rgba(#51BE92,1) 0%, rgba(#C5CF1D,1) 100%);
        padding: 80px 0;
    }
}
.l-gnav {
    &__list {
        display: block;
        padding: 0 16px;
    }
    &__item {
        padding: 0 1em;
        >a {
            font-size: 24px;
            padding: .7em 0;
        }
    }
    &__child {
        position: static;
        width: 100%;
        transform: translateX(0);
        padding: 10px 0;
        a {
            font-size: 18px;
            padding: .5em 1em;
        }
    }
}
}