@charset "utf-8";
// ===== color ===== //
$bg-base: #fff; //背景色（ベース）
$c-base: #333; //フォントカラー（ベース）
$c01: #51BE92; //みどり

// ===== Font Family ===== //
$sans: 'dnp-shuei-gothic-kin-std', sans-serif;
$serif: 'dnp-shuei-mincho-pr6n', serif;

// ===== function ===== //
@function vwpc( $size: 16 ) {
  $vw: $size * (100 / 1366) + vw;
  @return $vw;
}
@function vwsp( $size: 16 ) {
  $vw: $size * (100 / 390) + vw;
  @return $vw;
}
//property vw(12);//