@charset "utf-8";
.p-hour {
    padding: 30px;
    &__inner {
        max-width: 560px;
        margin: auto;
    }
    table {
        table-layout: fixed;
        font-family: $serif;
        line-height: 1.5;
        tr {
            &:first-child {
                border-bottom: 1px solid #FABDBF;
                th {
                    font-size: 18px;
                }
            }
        }
        th {
            text-align: center;
            width: 160px;
            border-right: 1px solid #F0CB94;
            padding: .5em 0;
        }
        td {
            text-align: center;
            padding: .5em 0;
        }
    }
    .hour__txt {
        font-size: 14px;
        line-height: 1.8;
        margin: 1em 0 0 32px;
    }
}
@media only screen and (max-width: 1024px) {
.p-hour {
    padding: 30px 20px;
    table {
        font-size: 14px;
        tr {
            &:first-child {
                th {
                    font-size: 14px;
                }
            }
        }
        th {
            width: 115px;
        }
    }
    .hour__txt {
        margin-left: 15px;
    }
}
}
@media only screen and (max-width: 767px) {
.p-hour {
    padding: 30px 16px 48px;
    &__inner {
        max-width: 100%;
    }
    table {
        th {
            width: 106px;
        }
    }
    .hour__txt {
        margin-left: 10px;
    }
}
}

.p-access {
    padding: 30px;
    color: #fff;
    line-height: 1.5;
    &__inner {
        max-width: 560px;
        margin: auto;
    }
    p {
        margin-bottom: 0;
    }
    .access__ttl {
        display: inline-block;
        font-size: 20px;
        font-family: $serif;
        border-bottom: 1px solid #fff;
        padding-bottom: .5em;
        margin-bottom: 1em;
    }
    .access__txt {
        p {
            padding-left: 35px;
            margin-bottom: .5em;
        }
        .ico {
            position: relative;
            font-size: 18px;
            background-repeat: no-repeat;
            background-position: left top;
            &-train {
                background-image: url(../img/ico-train.svg);
                background-size: 22px;
            }
        }
        .more {
            margin-top: 1.5em;
        }
    }
}
@media only screen and (max-width: 1024px) {
.p-access {
    padding: 30px 20px;
}
}
@media only screen and (max-width: 767px) {
.p-access {
    padding: 16px;
    &__inner {
        max-width: 100%;
    }
    .access__ttl {
        font-size: 16px;
    }
    .access__txt {
        p {
            font-size: 12px;
            padding-left: 20px;
        }
        .ico {
            font-size: 14px;
            &-train {
                background-size: 14px;
            }
        }
        .more {
            margin-top: 1em;
            font-size: 14px;
        }
    }
}
}
@media only screen and (max-width: 767px) {}