@charset "utf-8";
.l-footer {
    padding: 96px 0 0;
    position: relative;
    z-index: 1;
    background: #fff;
    &:before {
        content: "";
        width: 100%;
        height: 32px;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(135deg, rgba(#7FE2BA,1) 0%, rgba(#DEE389,1) 100%);
    }
    &__inner {
        padding-bottom: 60px;
    }
    &__logo {
        width: 240px;
        margin-bottom: 40px;
    }
    &__cont {
        line-height: 1.43;
        font-size: 14px;
    }
    &__copy {
        text-align: center;
        color: #fff;
        background: $c01;
        font-size: 12px;
        letter-spacing: 0;
        padding: 1.8em 0;
    }
    .footer-info {
        width: 55%;
        max-width: 390px;
        padding-right: 45px;
        border-right: 1px solid $c01;
        th,td {
            font-weight: 500;
            vertical-align: top;
            padding-bottom: 1rem;
        }
        th {
            width: 6em;
        }
    }
    .footer-nav {
        width: 45%;
        max-width: 505px;
        padding-left: 45px;
        gap: 45px 45px;
        &__box {
            .box__ttl {
                font-family: $serif;
                font-size: 16px;
                margin-bottom: 1.8em;
            }
            .box__list {
                li {
                    margin-bottom: 0.5em;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
.l-footer {
    padding: 80px 0 vwsp(42);
    &__inner {
        padding-bottom: 48px;
    }
    &__copy {
        padding: 1.8em 0;
    }
    .footer-info {
        width: 100%;
        max-width: 100%;
        padding-right: 0;
        border-right: none;
        margin-bottom: 48px;
    }
    .footer-nav {
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        gap: 32px 0;
        &__box {
            width: 100%;
        }
    }
}
}

.l-guide {
    background: #7DC3C0;
    position: relative;
    z-index: 1;
    &__cont {
        width: 50%;
    }
    &__cont--recruit {
        text-align: center;
        padding: 48px 30px;
        background: linear-gradient(0deg, rgba(#51BE92,1) 0%, rgba(#21B8D7,1) 100%);
        .recruit-cont {
            max-width: 562px;
            margin: auto;
            position: relative;
            &__img {
                position: relative;
                margin-bottom: 55px;
                &:before, &:after {
                    content: "";
                    position: absolute;
                }
                &:before {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    border-radius: 10px;
                    background: rgba(#000,.2);
                }
                &:after {
                    width: 100%;
                    height: 32px;
                    background: url(../img/guide-recruit-ttl.svg) no-repeat center;
                    background-size: auto 100%;
                    left: 0;
                    bottom: 0;
                    transform: translateY(50%);
                }
                img {
                    border-radius: 10px;
                }
            }
            &__txt {
                color: #fff;
            }
            &__more {
                a {
                    display: inline-block;
                    position: relative;
                    background: #fff;
                    color: $c01;
                    font-size: 18px;
                    line-height: 34px;
                    border-radius: 10px;
                    width: 182px;
                    margin-right: 15px;
                    &:after {
                        content: "▶︎";
                        position: absolute;
                        font-size: 12px;
                        top: 0;
                        right: -18px;
                        color: #fff;
                    }
                }
            }
        }
    }
    &__cont--info {
        .p-hour {
            background: #F9F9F9;
            padding: 50px 25px;
            &__inner {
                max-width: 610px;
            }
            table {
                tr {
                    &:first-child {
                        border-color: $c01;
                    }
                }
                th {
                    border-color: #C5CF1D;
                }
            }
        }
        .p-access {
            padding: 40px 25px;
        }
    }
}
@media only screen and (max-width: 767px) {
.l-guide {
    &__cont {
        width: 100%;
    }
    &__cont--recruit {
        padding: 32px 16px 48px;
        .recruit-cont {
            max-width: 100%;
            &__img {
                margin-bottom: 48px;
            }
            &__txt {
                font-size: 15px;
            }
        }
    }
    &__cont--info {
        .p-hour {
            padding: 32px 16px;
            &__inner {
                max-width: 100%;
            }
        }
        .p-access {
            padding: 32px 16px;
        }
    }
}
}

.l-access {
    text-align: center;
    position: relative;
    z-index: 1;
    background: #F7F7F7;
    &__inner {
        position: relative;
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../img/access-bg.png);
            background-repeat: no-repeat;
            background-position: left top;
            background-size: auto 100%;
            z-index: 1;
        }
    }
    &__cont {
        width: 50%;
        padding: 60px 48px 64px;
        position: relative;
    }
    &__cont--view {
        background: #F9F9F9;
    }
    &__ttl {
        position: relative;
        z-index: 2;
        margin-bottom: 45px;
        span {
            display: block;
        }
        .ttl--en {
            img {
                width: auto;
                height: 21px;
            }
        }
        .ttl--ja {
            font-family: $serif;
            font-size: 18px;
            letter-spacing: .16em;
            margin-top: 1.3em;
        }
    }
    .access-iframe {
        position: relative;
        z-index: 2;
        iframe {
            width: 100%;
            height: 326px;
        }
    }
}
@media only screen and (max-width: 1024px) {
.l-access {
    &__inner {
        padding: 0 30px;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    &__cont {
        width: 48%;
        padding: 60px 0 64px;
    }
}
}
@media only screen and (max-width: 767px) {
.l-access {
    &__inner {
        padding: 0 16px;
        &:before {
            background-image: url(../img/access-bg-sp.png);
        }
    }
    &__cont {
        width: 100%;
        padding: 32px 0;
    }
    &__ttl {
        margin-bottom: 24px;
    }
    .access-iframe {
        iframe {
            height: 200px;
        }
    }
}
}

.l-fixnav {
    position: fixed;
    z-index: 99;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    a {
        display: block;
    }
    img {
        width: 100%;
    }
}
@media only screen and (max-width: 767px) {
.l-fixnav {
    top: auto;
    bottom: 0;
    transform: translateY(0);
    width: 100%;
    &__list {
        display: flex;
    }
    &__item--tel {
        width: 54.87%;
    }
    &__item--web {
        width: 34.35%;
    }
    &__item--pagetop {
        width: 10.77%;
    }
}
}